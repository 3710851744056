'use strict';

const { queryFirst, queryAll, addClass, removeClass, hasClass, scrollTo, matchesBreakpoint } = require('../domUtil');
const { HIDDEN_CLASS } = require('../constants');
const backToTopTargetEls = queryAll('.js-back-to-top-target');
const backToTopContainerEl = queryFirst('.js-back-to-top');
const backToTopButtonEL = queryFirst('.back-to-top-icon', backToTopContainerEl);
let targetPosition = 0;

const toggleBackToTop = (showCondition) => {
    if (showCondition) {
        removeClass(backToTopButtonEL, HIDDEN_CLASS);
    } else if (!hasClass(backToTopButtonEL, HIDDEN_CLASS)) {
        addClass(backToTopButtonEL, HIDDEN_CLASS);
    }
}

if (hasClass(backToTopButtonEL, HIDDEN_CLASS)) {
    const { pageType } = backToTopContainerEl.dataset;

    if (backToTopTargetEls) {
        if ((pageType === 'PDP' || pageType === 'PLP')) {
            backToTopTargetEls.forEach((backToTopTargetEl) => {
                if ((matchesBreakpoint('md') && hasClass(backToTopTargetEl, 'js-desktop'))
                    || (matchesBreakpoint('xs') && !matchesBreakpoint('sm') && !hasClass(backToTopTargetEl, 'js-desktop'))) {
                    targetPosition = backToTopTargetEl.getBoundingClientRect().top + window.scrollY;
                }
            });
        }
    }

    window.addEventListener('scroll', () => {
        const documentElementScrollPos = document.documentElement.scrollTop;

        if (matchesBreakpoint('xs') && !matchesBreakpoint('sm')) {
            // show button on PD pages when scroll down the page on mobile
            if (pageType === 'PD' && (documentElementScrollPos > 0)) {
                removeClass(backToTopButtonEL, HIDDEN_CLASS);
                // show button on PDP and PLP when specific element is reached on mobile
            } else if (targetPosition && (pageType === 'PDP' || pageType === 'PLP')) {
                toggleBackToTop(documentElementScrollPos > targetPosition - window.innerHeight);
            }
        } else if (matchesBreakpoint('md')) {
            // show button on PDP and PD pages when scroll midway through the page on desktop
            if (pageType === 'PD' || pageType === 'PDP') {
                const documentHeight = document.body.scrollHeight || document.documentElement.scrollHeight;
                const scrollCenterPos = ((documentHeight / 2) - (window.innerHeight / 2));

                toggleBackToTop(documentElementScrollPos > scrollCenterPos);
                // show button on PLP when specific element is reached on desktop
            } else if (targetPosition && pageType === 'PLP') {
                toggleBackToTop(documentElementScrollPos > targetPosition - window.innerHeight);
            }
        }

        if (documentElementScrollPos === 0) {
            addClass(backToTopButtonEL, HIDDEN_CLASS);
        }
    });

    backToTopButtonEL.addEventListener('click', () => {
        scrollTo(0);
    });
}