window.jQuery = window.$ = require('jquery');
require('./imageZoom/magicZoom');
var processInclude = require('base/util');

const { initCarousel } = require('./components/carousel');

require('./polyfill');
require('./jquery.plugins.js');
require('bootstrap/js/src/popover');
require('bootstrap/js/src/dropdown.js');
require('jquery-zoom');

document.addEventListener('DOMContentLoaded', () => {
  processInclude(require('./components/menu'));
  processInclude(require('base/components/cookie'));
  processInclude(require('base/components/consentTracking'));
  processInclude(require('./components/backToTop'));
  processInclude(require('./components/footer'));
  processInclude(require('./components/miniCart'));
  processInclude(require('./components/headerSearch'));
  processInclude(require('base/components/collapsibleItem'));
  processInclude(require('./components/search'));
  processInclude(require('./clientSideValidation'));
  processInclude(require('base/components/countrySelector'));
  processInclude(require('./components/modal'));
  processInclude(require('./components/toolTip'));
  processInclude(require('./popover'));
  processInclude(require('./postLogin/postLogin'));
  processInclude(require('./animateUtil'));
  processInclude(require('./components/video'));
  processInclude(require('./product/sizeChart'));
  processInclude(require('./product/wishlist'));
  processInclude(require('./components/csrfErrorModal'));
  processInclude(require('./components/serviceWorkerDeRegistration'));
  processInclude(require('./components/textAreaCounter'));
  processInclude(require('./imageLazyLoad'));
  processInclude(require('./scriptLazyLoad'));
  processInclude(require('./components/updateURLPath'));
  processInclude(require('./onetrust'));
  initCarousel();

  $('.invalid-feedback').attr('role', 'alert');

  // Active state added for custome service page navigation links
  $(
    '.' +
    $('.active-link:not(.loyalty-terms-condition-modal)').data('active-link')
  ).addClass('active');

  // Scroll to CA section when navigated from footer link
  if (window.location.hash === '#ca-section') {
    const caSection = document.querySelector('#ca-section');
    caSection.scrollIntoView({behavior: "smooth", block: "start"});
  }
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('./components/staggeredContent');
