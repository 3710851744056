// Classnames
const DISABLED_CLASS = 'disabled-section';
const NOT_AVAILABLE_CLASS = 'not-available';
const ISPU_STORE_NAME_CLASS = 'store-name';
const ISPU_SERVICE_ERROR_ATTR_NAME = 'data-ispu-service-down';
const ISPU_SERVICE_BLOCKED = 'service-blocked';
const PRE_ORDER_CLASS = 'pre-order';
const SHIP_TO_LOW_INVENTORY_CLASS = 'ship-to-low-inventory';
const CHECKOUT_HIDDEN_CLASS = 'checkout-hidden';
const PDP_SET_PAGE = 'product-set-item';

// Container selectors
const PRODUCT_DETAIL_CONTAINER_SELECTOR = '.product-detail';
const PDP_CONTAINER_SELECTOR = '.pdp-container';
const SHIPPING_PREFERENCES_CONTAINER_SELECTOR = '.shipping-preferences';
const STORE_DETAIL_CONTAINER_SELECTOR = SHIPPING_PREFERENCES_CONTAINER_SELECTOR + ' .store-detail-container';

// Radio buttons classes
const PREFERENCE_SELECTOR = 'input[name="shipping-preference"]:checked';
const ISPU_PREFERENCE_CONTAINER_SELECTOR = '.ispu-preference-container';
const ISPU_PREFERENCE_CONTENT_SELECTOR = ISPU_PREFERENCE_CONTAINER_SELECTOR + ' .content';
const SHIPPING_PREFERENCE_RADIO_BUTTON_SELECTOR = 'input[name=shipping-preference]';
const ISPU_RADIO_SELECTOR = SHIPPING_PREFERENCES_CONTAINER_SELECTOR + ' .preference-ispu';
const SHIP_TO_ADDRESS_RADIO_SELECTOR = SHIPPING_PREFERENCES_CONTAINER_SELECTOR + ' .preference-ship-to-address';
const SHIP_TO_ADDRESS_CONTENT_SELECTOR = '.ship-to-address-preference-container .content';
const RADIO_BUTTON_LABEL_SELECTOR = '.form-check-label';

// Radio button values
const SHIPPING_PREFERENCE_KEY = 'SHIPPING_PREFERENCE';
const ISPU_RADIO_VALUE = 'ISPU';
const SHIP_TO_RADIO_VALUE = 'SHIP_TO_ADDRESS';
const BOLT_PAY = 'BOLT_PAY';

// Change Store link
const CHANGE_STORE_LINK_SELECTOR = '.change-store-link';

// Store name and store address section
const STORE_DETAIL_SELECTOR = SHIPPING_PREFERENCES_CONTAINER_SELECTOR + ' .store-detail';

// Inventory and service messages
const STORE_SERVICE_INFO_MESSAGE_SELECTOR = SHIPPING_PREFERENCES_CONTAINER_SELECTOR + ' .service-info-message';
const STORE_AVAILABILITY_MESSAGE_SELECTOR = SHIPPING_PREFERENCES_CONTAINER_SELECTOR + ' .inventory-message';

const ISPU_INFO_MESSAGE_SELECTOR = SHIPPING_PREFERENCES_CONTAINER_SELECTOR + ' .ispu-info-message';
const SHIP_TO_INFO_MESSAGE_SELECTOR = SHIPPING_PREFERENCES_CONTAINER_SELECTOR + ' .ship-to-info-message';

const ISPU_SELECT_STYLE_MESSAGE_SELECTOR = ISPU_PREFERENCE_CONTAINER_SELECTOR + ' .select-size-info-msg';

//Availability Message Element
const AVAILABILITY_MSG_SELECTOR = '.product-availability .availability-message-text';
//Inventory Message Element of ISPU
const INVENTORY_MSG_SELECTOR = '.inventory-message .availability-message-text';
//Low Stock Message Element of ISPU
const LOW_STOCK_ISPU_SELECTOR = '.product-availability .ispu-low-stock-msg';
//Regular Expression for Valid WishList Name
const WISHLIST_NAME_REGEX = /^[ A-Za-z0-9\]\.()[{},;:"'?!\u2026\u2018\u2019\u201B\u201C\u201D\u2014\u2013-]*$/;
//Regular Expression for Valid Postal Code for Loyalty
const LOYALTY_POSTAL_CODE_REGEX = /^\d{5}$/;

const TRACKER_FUNCTIONAL_COOKIE = 'C0003';
const MY_ACCOUNT = 'myAccount';
const ORDER_CONFIRMATION = 'orderConfirmation';

module.exports = {
    DISABLED_CLASS,
    NOT_AVAILABLE_CLASS,
    ISPU_STORE_NAME_CLASS,
    PRE_ORDER_CLASS,
    SHIP_TO_LOW_INVENTORY_CLASS,
    ISPU_SERVICE_ERROR_ATTR_NAME,
    ISPU_SERVICE_BLOCKED,
    CHECKOUT_HIDDEN_CLASS,
    PDP_SET_PAGE,

    PRODUCT_DETAIL_CONTAINER_SELECTOR,
    PDP_CONTAINER_SELECTOR,
    SHIPPING_PREFERENCES_CONTAINER_SELECTOR,
    STORE_DETAIL_CONTAINER_SELECTOR,

    PREFERENCE_SELECTOR,
    ISPU_PREFERENCE_CONTAINER_SELECTOR,
    ISPU_PREFERENCE_CONTENT_SELECTOR,
    SHIP_TO_ADDRESS_CONTENT_SELECTOR,
    SHIPPING_PREFERENCE_RADIO_BUTTON_SELECTOR,
    ISPU_RADIO_SELECTOR,
    SHIP_TO_ADDRESS_RADIO_SELECTOR,
    RADIO_BUTTON_LABEL_SELECTOR,
    SHIPPING_PREFERENCE_KEY,
    ISPU_RADIO_VALUE,
    SHIP_TO_RADIO_VALUE,
    BOLT_PAY,

    CHANGE_STORE_LINK_SELECTOR,
    STORE_DETAIL_SELECTOR,
    STORE_SERVICE_INFO_MESSAGE_SELECTOR,
    STORE_AVAILABILITY_MESSAGE_SELECTOR,
    ISPU_INFO_MESSAGE_SELECTOR,
    SHIP_TO_INFO_MESSAGE_SELECTOR,
    ISPU_SELECT_STYLE_MESSAGE_SELECTOR,
    AVAILABILITY_MSG_SELECTOR,
    INVENTORY_MSG_SELECTOR,
    LOW_STOCK_ISPU_SELECTOR,
    WISHLIST_NAME_REGEX,
    TRACKER_FUNCTIONAL_COOKIE,
    LOYALTY_POSTAL_CODE_REGEX,
    MY_ACCOUNT,
    ORDER_CONFIRMATION
};
