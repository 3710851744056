// OOTB Code
'use strict';
const { queryFirst, addClass, queryAll, isInViewport, removeClass, hasClass, scrollTo } = require('./domUtil');
const { errorTextTemplate } = require('./templates');
const checkoutContainerEl = document.getElementById('checkout-main');
const checkoutContainer = queryFirst('.data-checkout-stage');
const showClass = 'show';

/**
 * Function to scroll to th first invalid input if it is not in view port
 * @param {HTMLElement} formEl - Form to be validated
 */
function scrollToError(formEl) {
    const headerEl = queryFirst('.main-header');
    const headerHeight = headerEl ? headerEl.offsetHeight : 0;
    const invalidEl = $(formEl)
        .find('.is-invalid')
        .first();

    if (invalidEl[0] && !isInViewport(invalidEl[0])) {
        scrollTo(invalidEl.offset().top - headerHeight);
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (checkoutContainer) {
        const billingForm = queryFirst('.billing-address-form', checkoutContainer);
        if (this === billingForm) {
            return valid;
        }
    }
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        scrollToError(this);
        $(this)
            .find('input, select')
            .each(function () {
                if (!this.validity.valid) {
                    $(this).trigger('invalid', this.validity);
                }
            });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form)
        .find('.form-control.is-invalid')
        .removeClass('is-invalid');
}

// LP custom changes start
/**
 * Enable or disable button based on form validity status
 * @param {Object} formElement - form to be validated
 */
function enableOrDisableButton(form) {
    const button = queryFirst('.enable-button-onvalidate', form);
    const isValid = queryAll('.is-invalid', form).length === 0;

    button.disabled = !isValid;
}

// LP custom changes end

/**
 * function returns validation message basedd on fields invalid state
 * @returns {string} Validation message
 */
function getValidationMessage() {
    let { validationMessage } = this;
    const $this = $(this);
    const patternMismatchValue = $this.data('pattern-mismatch');
    const rangeErrorValue = $this.data('range-error');
    const missingErrorValue = $this.data('missing-error');
    const badInputErrorValue = $this.data('bad-input-error');
    const stepMismatchErrorValue = $this.data('step-mismatch-error');
    const { patternMismatch, rangeOverflow, rangeUnderflow, tooLong, tooShort, valueMissing, badInput, stepMismatch } = this.validity;

    addClass(this, 'is-invalid');
    enableOrDisableButton(this.closest('form'));

    if (patternMismatch && patternMismatchValue) {
        validationMessage = patternMismatchValue;
    } else if ((rangeOverflow || rangeUnderflow) && rangeErrorValue) {
        validationMessage = rangeErrorValue;
    } else if ((tooLong || tooShort) && rangeErrorValue) {
        validationMessage = rangeErrorValue;
    } else if (valueMissing && missingErrorValue) {
        validationMessage = missingErrorValue;
    } else if (badInput && badInputErrorValue) {
        validationMessage = badInputErrorValue;
    } else if (stepMismatch && stepMismatchErrorValue) {
        validationMessage = stepMismatchErrorValue;
    }
    return validationMessage;
}

/**
 * Displays invalid forms error messages
 * @param {jQuery.event} e - Form invalid event object
 */
function onFormInvalidHandler(e) {
    e.preventDefault();
    this.setCustomValidity('');
    if (!this.validity.valid) {
        $('.btn-show-details').trigger('click');
        $(this)
            .parents('.form-group, .input-group')
            .find('.invalid-feedback')
            .text(getValidationMessage.apply(this));
        if (!checkoutContainerEl) {
            scrollToError(this.form);
        }
    }
}

/**
 * Form submission event handler
 * @param {jQuery.event} e - Form submit event object
 * @returns {boolean} true if the form is valid
 */
function onFormSubmit(e) {
    return validateForm.call(this, e);
}


/**
 * Function to check if the password/email and confirm password/email matches in form
 * @param {string} fieldId - ID of the form field
 * @param {boolean} isFieldsMatched - describes if fields matched
 * @param {Object} thisEl - this element
 * @return {Object} - updated form validity and password/email match
 */
function checkFieldsMatch(fieldId, isFieldsMatched, thisEl) {
    const newFieldValue = queryFirst(fieldId).value;
    const confirmFieldValue = thisEl.value;

    if (confirmFieldValue && newFieldValue && confirmFieldValue !== newFieldValue) {
        isFieldsMatched = false;
    }

    return isFieldsMatched;
}

/**
 * Runs validation on all INPUT elements within the specified containing element.
 * @param {element} container - The containing element to validate all inputs within
 */
function validateInputs(container) {
    if (!container) return;

    container.querySelectorAll('input').forEach(element => element.checkValidity());
}

/**
 * @description Returns function that checks address length, display error and cut input value
 * @param {number} addressLengthLimit - max address length from site preference
 * @param {element} addressForm - address form element
 * @returns {function} - function that checks address length, display error and cut input value
 */
const getAddressValidationHandler = (addressLengthLimit, addressForm) => {
    const formHelpers = require('base/checkout/formErrors');
    let timeoutID;

    return function (event) {
        if (timeoutID) {
            clearTimeout(timeoutID);
        }

        const addressEl = event.target;
        timeoutID = setTimeout(() => {
            if (addressEl.value.length > addressLengthLimit) {
                formHelpers.loadFormErrors(addressForm, {
                    [addressEl.getAttribute('name')]: addressEl.getAttribute('data-config-limit-error')
                });
                addressEl.value = addressEl.value.slice(0, addressLengthLimit);
            }
        }, 500);
    };
};

module.exports = {
    invalid: function () {
        $('form input, form textarea, form select').on('invalid', onFormInvalidHandler);
    },

    ajaxFormInvalid: function (formElSelector) {
        if (!formElSelector) return;
        $(`${formElSelector} input, ${formElSelector} textarea, ${formElSelector} select`).on('invalid', onFormInvalidHandler);
    },

    submit: function () {
        $('form').on('submit', onFormSubmit);
    },

    ajaxFormSubmit: function (formElSelector) {
        if (!formElSelector) return;
        $(`${formElSelector}`).on('submit', onFormSubmit);
    },

    validateAddressForm: function () {
        const addressForm = queryFirst('.address-form');
        if (addressForm) {
            const address1 = queryFirst('#address1', addressForm);
            const address2 = queryFirst('#address2', addressForm);
            const addressLimit = address1.getAttribute('data-address-lengt-limit');
            if (addressLimit) {
                const addressValidationHandler = getAddressValidationHandler(addressLimit, addressForm);
                address1.addEventListener('keyup', addressValidationHandler);
                address2.addEventListener('keyup', addressValidationHandler);
            }
        }
    },

    validateCheckoutForm: function () {
        // LP custom changes start
        if (checkoutContainer) {
            const guestCustomerForm = queryFirst('.guest-form', checkoutContainer);
            const registeredCustomerForm = queryFirst('.registered-form', checkoutContainer);
            const shippingForm = queryFirst('.shipping-form', checkoutContainer);
            const billingForm = queryFirst('.billing-address-form', checkoutContainer);
            const submitShippingBtn = queryFirst('.submit-shipping', checkoutContainer);
            const billingAddress = queryFirst('.billing-address', billingForm);
            const reauthForm = queryFirst('.reauth-payment-form', checkoutContainer);

            if (submitShippingBtn !== null) {
                submitShippingBtn.disabled = false;
            }
            // LP custom changes end

            $('.guest-form, .registered-form, .shipping-form, .billing-address-form, .gift-card-container')
                .find('input, textarea')
                .on('blur', function () {
                    if (this.validity.valid) {
                        removeClass(this, 'is-invalid');
                    } else {
                        $(this)
                            .parents('.form-group, .input-group')
                            .find('.invalid-feedback')
                            .text(getValidationMessage.apply(this));
                    }
                });
            $('.shipping-form, .billing-address-form')
                .find('select, input[type="radio"], input[type="checkbox"]')
                .on('change', function () {
                    if (this.validity.valid) {
                        removeClass(this, 'is-invalid');
                    } else {
                        $(this)
                            .parents('.form-group')
                            .find('.invalid-feedback')
                            .text(getValidationMessage.apply(this));
                    }
                });
            if (!reauthForm) {
                const submitCustomerBtn = queryFirst('.submit-customer', checkoutContainer);
                const submitCustomerLoginBtn = queryFirst('.submit-customer-login', checkoutContainer);
                if (submitCustomerBtn) {
                    submitCustomerBtn.addEventListener('click', function (event) {
                        if (guestCustomerForm.checkValidity && !guestCustomerForm.checkValidity()) {
                            event.stopPropagation();
                            scrollToError(guestCustomerForm);
                        }
                    });
                }
                if (submitCustomerLoginBtn) {
                    submitCustomerLoginBtn.addEventListener('click', function (event) {
                        if (registeredCustomerForm.checkValidity && !registeredCustomerForm.checkValidity()) {
                            event.stopPropagation();
                            scrollToError(registeredCustomerForm);
                        }
                    });
                }

                const shippingAddress1 = queryFirst('.shippingAddressOne', shippingForm);
                const shippingAddress2 = queryFirst('.shippingAddressTwo', shippingForm);
                const shippingAddressLimit = shippingAddress1.getAttribute('data-address-lengt-limit');
                if (shippingAddressLimit) {
                    const shippingAddressValidationHandler = getAddressValidationHandler(shippingAddressLimit, shippingForm);
                    shippingAddress1.addEventListener('keyup', shippingAddressValidationHandler);
                    shippingAddress2.addEventListener('keyup', shippingAddressValidationHandler);
                }

                const billingAddress1 = queryFirst('.billingAddressOne', billingForm);
                const billingAddress2 = queryFirst('.billingAddressTwo', billingForm);
                const billingAddressLimit = billingAddress1.getAttribute('data-address-lengt-limit');
                const billingAddressValidationHandler = getAddressValidationHandler(billingAddressLimit, billingForm);
                if (billingAddressLimit) {
                    billingAddress1.addEventListener('keyup', billingAddressValidationHandler);
                    billingAddress2.addEventListener('keyup', billingAddressValidationHandler);
                }

                shippingForm.addEventListener('submit', function (event) {
                    event.preventDefault();
                    setTimeout(function () {
                        submitShippingBtn.click();
                    }, 0);
                });

                submitShippingBtn.addEventListener('click', function (event) {
                    const shippingForm = queryFirst('.shipping-form');
                    const errorElement = queryFirst('.error-message');
                    const { shippingError } = this.dataset;
                    const { addressMode } = shippingForm.dataset;
                    const { customerType } = checkoutContainer.dataset;

                    removeClass(errorElement, showClass);

                    if (customerType === 'registered') {
                        if (addressMode === 'new' || addressMode === 'details') {
                            if (shippingForm.checkValidity && !shippingForm.checkValidity()) {
                                event.stopPropagation();
                                errorElement.innerHTML = errorTextTemplate(shippingError);
                                addClass(errorElement, showClass);
                                scrollTo(0);
                            }
                        } else {
                            const giftMessageEl = document.getElementById('giftMessage');
                            if (giftMessageEl && !giftMessageEl.validity.valid) {
                                addClass(giftMessageEl, 'is-invalid');
                                event.stopPropagation();
                            } else {
                                removeClass(giftMessageEl, 'is-invalid');
                            }
                        }
                    } else if (shippingForm.checkValidity && !shippingForm.checkValidity()) {
                        event.stopPropagation();
                        errorElement.innerHTML = errorTextTemplate(shippingError);
                        addClass(errorElement, showClass);
                        scrollTo(0);
                    }
                });

                const submitPaymentBtn = queryFirst('.submit-payment', checkoutContainer);

                submitPaymentBtn.addEventListener('click', function (event) {
                    const { customerType } = checkoutContainer.dataset;
                    const isPaymentDisabled = hasClass(queryFirst('.payment-information'), 'disabled-section');
                    const isCreditTabActive = hasClass(queryFirst('.credit-card-tab', checkoutContainer), 'active');
                    const isNewPayment = $('.payment-information').data('is-new-payment');
                    const isRequired = !isPaymentDisabled && isCreditTabActive;

                    if (customerType === 'registered') {
                        const savedPaymentCvv = queryFirst('.saved-payment-security-code');
                        if (savedPaymentCvv) {
                            savedPaymentCvv.required = isRequired && !isNewPayment;
                        }
                    }
                    if (billingForm.checkValidity && !billingForm.checkValidity()) {
                        scrollToError(billingForm);
                        event.stopPropagation();
                    }
                });

                billingForm.addEventListener('submit', function (event) {
                    event.preventDefault();
                    setTimeout(function () {
                        // if the payment button is hidden, it's because CC is not active
                        // and therefore we shouldn't click it
                        if (!hasClass(submitPaymentBtn, 'd-none')) {
                            submitPaymentBtn.click();
                        }
                    }, 0);
                });

                queryFirst('#billingAddressSelector').addEventListener('change', () => {
                    if (checkoutContainer.dataset.checkoutStage === 'payment') {
                        clearForm(billingAddress.closest('form'));
                        validateInputs(billingAddress);
                    }
                });

                const addressLinks = queryAll('.billing-address-block .address-links a');

                addressLinks.forEach(link => {
                    link.addEventListener('click', () => {
                        clearForm(billingAddress.closest('form'));
                    });
                });

                if (checkoutContainer.dataset.checkoutStage === 'payment') {
                    $(() => {
                        validateInputs(billingAddress);
                    });
                }
            } else {
                const submitReauthBtn = queryFirst('.submit-payment-button', checkoutContainer);
                submitReauthBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    $.spinner().start();
                    submitReauthBtn.disabled = true;
                    if (billingForm.checkValidity && !billingForm.checkValidity()) {
                        scrollToError(billingForm);
                        submitReauthBtn.disabled = false;
                        $.spinner().stop();
                        event.stopPropagation();
                    } else {
                        billingForm.submit();
                    }
                });
            }
        }
    },

    enableFormSubmitButton: function () {
        const formElements = queryAll('.enable-form-validate');

        formElements.forEach(form => {
            const button = queryFirst('.enable-button-onvalidate', form);

            const callback = function () {
                if (this.validity.valid) {
                    removeClass(this, 'is-invalid');
                } else {
                    addClass(this, 'is-invalid');
                    queryFirst('.invalid-feedback', this.closest('.form-group')).textContent = getValidationMessage.apply(this);
                }

                enableOrDisableButton(form);
            };

            const checkMatchedFields = function () {
                const { matchId } = this.dataset;
                const confirmEl = queryFirst(`.js-matched-confirm-field[type=${this.type}]`, form);
                let isFieldMatched = checkFieldsMatch(matchId, true, this);

                if (confirmEl && this.validity.valid) {
                    if (isFieldMatched) {
                        removeClass(confirmEl, 'is-invalid');
                    } else {
                        addClass(confirmEl, 'is-invalid');
                        queryFirst('.invalid-feedback', confirmEl.closest('.form-group')).textContent = confirmEl.dataset.mismatchError;
                    }
                }

                enableOrDisableButton(form);
            };

            const checkPasswordFields = function () {
                const selectorContainer = $('.valid-password-info li');
                const notMatchedClass = 'pwd-criteria-not-matched';
                const matchedClass = 'pwd-criteria-matched';

                if (this.validity.valid) {
                    removeClass(this, 'is-invalid');
                    selectorContainer.removeClass(notMatchedClass);
                    selectorContainer.addClass(matchedClass);
                } else {
                    const $this = $(this);
                    const thisValue = $this.val();

                    if (hasClass(form, 'change-password-form') && thisValue) {
                        selectorContainer.each(function (i) {
                            const item = selectorContainer[i];
                            const regexPattern = item.dataset.regexpattern;
                            const regexValue = new RegExp(regexPattern);
                            if (regexValue.test(thisValue)) {
                                removeClass(item, notMatchedClass);
                                addClass(item, matchedClass);
                            } else {
                                removeClass(item, matchedClass);
                                addClass(item, notMatchedClass);
                            }
                        });

                        selectorContainer.closest('ul').addClass('padding-none');
                        queryFirst('.invalid-feedback', this.closest('.form-group')).textContent = '';
                    }
                }
            };

            if (form && button) {
                const $form = $(form);

                $form.find('input, textarea').on('blur', callback);
                $form.find('.js-matched-field').on('blur', checkMatchedFields);
                $form.find('#newPassword, #registration-form-password').on('keyup', checkPasswordFields);
                $form.find('select, input[type="radio"], input[type="checkbox"]').on('change', callback);
                $form.on('change', '.g-recaptcha-response', callback);
            }
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            if (!$('.reauth-payment-form').length) {
                clearForm($(this).parents('form'));
            }
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        validateInputs,
        clearForm,
        enableOrDisableButton,
        getValidationMessage
    }
};
