'use strict';

const { queryFirst, queryAll, removeClass, addClass } = require('../domUtil');
const parentEl = queryFirst('.lp-sizechart');
const toggleClass = 'show-menu';
let sizeChartLinkTarget = '';
const KEYCODE_TAB = 9;
const TabKey = 'Tab';

/**
 * Closes sizechart category dropdown menu
 * @param  {Object} el element clicked
 */
const closeCategoryMenu = () => {
    const sizechartCatEls = queryAll('#sizechart-dropdown-menu-id a.nav-link');
    const sizeChartMenu = queryFirst('.lp-sizechart-category-dropdown');
    removeClass(sizeChartMenu, toggleClass);
    sizechartCatEls.forEach(el => {
        el.tabIndex = -1;
    });
};

/**
 * Handles size chart click
 * @param  {Object} event element clicked
 */
const handleSizeChartClick = event => {
    const { target } = event;
    const sizeChartMenu = queryFirst('.lp-sizechart-category-dropdown');
    const dropdownParentEl = queryFirst('.lp-sizeguide-category-dropdown');
    const sizeChartCatBtn = queryFirst('.lp-sizechart-category-btn', dropdownParentEl);
    const sizechartCatEls = queryAll('#sizechart-dropdown-menu-id a.nav-link');

    if (target.matches('#sizechart-close') || target.matches('.size-chart-bg')) {
        // close sizechart modal
        removeClass(queryFirst('.size-chart-collapsible', parentEl), 'active');
        removeClass(queryFirst('.lp-slideout', parentEl), 'lp-slideout-open');
        removeClass(document.body, 'no-scroll', 'size-chart-visible');
        const setStickyNav = queryFirst('.custom-set-detail-sticky-nav');
        if (setStickyNav) {
            removeClass(setStickyNav, 'd-none');
        }
        sizeChartLinkTarget.focus();
    } else if (target.matches('#sizechart-menu-toggle')) {
        // toggle sizechart category menu
        if (sizeChartMenu.classList.length && sizeChartMenu.classList.contains(toggleClass)) {
            closeCategoryMenu(target);
        } else {
            addClass(sizeChartMenu, toggleClass);
            sizechartCatEls.forEach(el => {
                el.tabIndex = 0;
            });
            sizechartCatEls[0].focus();
        }
        queryFirst('.lp-sizechart-category-dropdown-menu .nav-item').focus();
    } else if (target.matches('.lp-sizechart .nav-link')) {
        // select sizechart category value
        closeCategoryMenu(target);
        sizeChartCatBtn.textContent = target.textContent;
        sizeChartCatBtn.focus();
    } else if (target.matches('.size-chart .size-chart-link')) {
        sizeChartLinkTarget = target;
        if (sizeChartCatBtn) {
            sizeChartCatBtn.focus();
        }
    }
};

module.exports = () => {
    document.addEventListener('click', handleSizeChartClick);
};
