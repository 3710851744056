'use strict';

const isFitPredictorEnabled = !!document.getElementById('fitPredictorEnabled');
const noop = function () { };

/**
 * Native querySelector to find first element in the dom with given selector
 *
 * @param  {string | Object} selector Target selector element.
 * @param  {string | Object} parent  Parent element, if it not provided
 * fallback to document.
 * @return {Object} returns the target as a DOM node
 */
const queryFirst = (selector, parent = document) => {
    if (!parent) return null;
    return parent.querySelector(selector);
};

/**
 * Native querySelectorAll to find all the dom element with given selector.
 *
 * @param  {string | Object} selector Target selector element.
 * @param  {string | Object} parent Parent element, if it not provided
 * fallback to document.
 * @return {nodeList | Array} Returns the target as a DOM node.
 */
const queryAll = (selector, parent = document) => {
    if (!parent) return null;
    return [].slice.call(parent.querySelectorAll(selector), 0);
};

/**
 * Looks for availability of class or classes in the classList of particular element.
 *
 * @param  {string | Object} el Target  element.
 * @param  {string | Array} val class name or class names as list of arguments.
 * @return {boolean} Returns availability of class in the target element.
 */
const hasClass = (el, ...classes) => {
    const { classList } = el;
    for (let c = 0, n = classes.length; c < n; c++) {
        if (!classList.contains(classes[c])) return false;
    }
    return true;
};

/**
 * Wraps a method to set up feature flagging
 * 
 * @param  {function} method The method to run
 * @return {function} Returns the method passed in if the feature is enabled, otherwise returns a noop method
 */
const featureSwitch = method => (isFitPredictorEnabled && method) || noop;

/**
 * This function sets the site context for Secret Sauce based on the channel type
 */
function siteContext() {
    const deviceWidth = window.screen.width;
    let channelValue = 'desktop';
    if (deviceWidth >= 768 && deviceWidth < 1024) {
        channelValue = 'tablet';
    } else if (deviceWidth < 768) {
        channelValue = 'mobile';
    }
    ssp('set', 'site', {
        layout: channelValue,
        currency: 'USD',
        language: 'en',
        market: 'US',
        shippingCountry: 'US'
    });
}

/**
 * This function sets the customer context for Secret Sauce
 */
function custContext() {
    const custID = document.getElementById('ssCustomer').value;
    const email = document.getElementById('ssEmail').value;
    if (custID && custID !== '') {
        ssp('set', 'user', {
            userId: custID,
            emailHash: email
        });
    }
}

/**
 * This function sets the page context for Secret Sauce based on the product page type
 * @param  {string | Object} bc breadcrumb value.
 * @param  {string | Object} pt  product page type.
 */
function pageContext(pt) {
    ssp('set', 'page', {
        type: pt // 'cat', 'pdp', 'pdp-set', 'quickview'
    });
}

/**
 * This function retrieves the price of the product from price container for Secret Sauce
 * @param {Object} container product container
 * @param  {string | Object} priceContainerId price container Id.
 * @return {float} returns price
 */
function getPriceFromContainer(priceContainerId, container) {
    const price = queryFirst(priceContainerId, container);
    if (price && price.value) {
        return parseFloat(price.value.replace('$', ''));
    }
    return 0.0;
}

/**
 * This function retrieves the standard price of the product for Secret Sauce
 * @param {Object} container product continer
 * @return {float} returns standard price
 */
function getPrice(container) {
    return getPriceFromContainer('.ssPrice', container);
}

/**
 * This function sets the standard price of the product for Secret Sauce
 * @param {float} newPrice standard price
 * @param {Object} container product container
 */
function setPrice(newPrice, container) {
    const priceElement = queryFirst('.ssPrice', container);
    if (priceElement) {
        priceElement.value = newPrice;
    }
}

/**
 * This function retrieves the sale price of the product for Secret Sauce
 * @param {Object} container product container
 * @return {float} returns sale price
 */
function getSalePrice(container) {
    return getPriceFromContainer('.ssSalesPrice', container);
}

/**
 * This function sets the sale price of the product for Secret Sauce
 * @param {float} newSalePrice sale price
 * @param {Object} container product container
 */
function setSalePrice(newSalePrice, container) {
    const salesPriceElement = queryFirst('.ssSalesPrice', container);
    if (salesPriceElement) {
        salesPriceElement.value = newSalePrice;
    }
}

/**
 * This function retrieves the selected color of the product for Secret Sauce
 * @param {Object} container product container
 * @return {string} returns selected product color
 */
function getSelectedColor(container) {
    const colorElement = queryFirst('.ssColor', container);
    return (colorElement && colorElement.value) ? colorElement.value.replace(/\s*\n\s*/g, '') : 'unknown';
}

/**
 * This function sets the selected color of the product for Secret Sauce
 * @param {string} newColor selected product color
 * @param {Object} container product container
 */
function setSelectedColor(newColor, container) {
    const colorElement = queryFirst('.ssColor', container);
    if (colorElement) {
        colorElement.value = newColor;
    }
}

/**
 * This function retrieves the selected size of the product for Secret Sauce
 * @param {Object} container product container
 * @return {string} returns selected product size
 */
function getSelectedSize(container) {
    const sizeElement = queryFirst('.ssSize', container);
    return (sizeElement && sizeElement.value != 'unknown') ? sizeElement.value : '';
}

/**
 * This function sets the selected size of the product for Secret Sauce
 * @param {string} newSize selected product size
 * @param {Object} container product container
 */
function setSelectedSize(newSize, container) {
    const sizeElement = queryFirst('.ssSize', container);
    if (sizeElement) {
        sizeElement.value = newSize;
    }
}

/**
 * This function updates product data for Secret Sauce
 * @param {string} color selected product color
 * @param {string} size selected product size
 * @param {float} price selected product standard price
 * @param {float} salePrice selected product sale price
 * @param {Object} container product container
 */
function updateProductData(color, size, price, salePrice, container) {
    if (getSelectedColor(container) !== color) setSelectedColor(color, container);
    if (getSelectedSize(container) !== size) setSelectedSize(size, container);
    if (getPrice(container) !== price) setPrice(price, container);
    if (getSalePrice(container) !== salePrice) setSalePrice(salePrice, container);
}

/**
 * This function retrieves the available sizes for the product swatch selected
 * @param {Object} container product container
 * @return {Array} returns sizes list of the product
 */
function getSizes(container) {
    let sizeArray = [];
    if (hasClass(container, 'pdp-container')) {
        queryAll('.scrollable-product-item .size-container .size-list .size-btn', container).forEach((element) => {
            const size = element.dataset.attrValue;
            if (size !== '' && size !== undefined) {
                sizeArray.push(size);
            }
        });
       
    } else  {
        queryAll('.size-container .size-list .size-btn', container).forEach((element) => {
            const size = element.dataset.attrValue;
            if (size !== '' && size !== undefined) {
                sizeArray.push(size);
            }
        });
    }
  
    return sizeArray;
}

/**
 * This function retrieves the variant ID of the selected product
 * @return {string} returns product ID
 */
function getVariant() {
    return $('.product-detail').attr('data-masterid');
}

/**
 * This function enables the Fit Predictor link from Secret Sauce
 */
function startFP() {
    ssp('start', 'fitpredictor');
}

/**
 * This function enables the Style Finder link from Secret Sauce
 */
function startSF() {
    ssp('show', 'stylefinder');
}

/**
 * This function enables the Outfit Maker link from Secret Sauce
 */
function startOM() {
    ssp('show', 'outfitmaker');
}

/**
 * This function retrieves the available sizes that are in stock for the product swatch selected
 * @param {Object} container product container
 * @return {Array} returns sizes list of the product
 */
function getAvailableSizes(container) {
    let sizeArray = [];
    if (hasClass(container, 'pdp-container')) {
        queryAll('.scrollable-product-item .size-container .size-list .size-btn', container).forEach((element) => {
            let size = element.dataset.attrValue;
            if (size !== '') {
                if (!hasClass(element, 'not-available')) {
                    sizeArray.push(size);
                }
            }
        }); 
    } else {
        queryAll('.size-container .size-list .size-btn', container).forEach((element) => {
            let size = element.dataset.attrValue;
            if (size !== '') {
                if (!hasClass(element, 'not-available')) {
                    sizeArray.push(size);
                }
            }
        });
    }
    return sizeArray;
}


/**
 * This function extracts the selected Fit
 */
function getFitName() {
    const selectedFitElement = document.querySelector('.non-color-container .non-color-list.fitsize-swatches .fit-product-type.selected');
    let fitName = '';
    if (selectedFitElement && selectedFitElement.hasAttribute('data-fitsizeswatch-name')) {
        const fitSwatchName = selectedFitElement.getAttribute('data-fitsizeswatch-name');
        // Get the data attribute value
        if (fitSwatchName === 'Standard') {
            fitName = 'regular';
        } else if (fitSwatchName === 'Plus') {
            fitName = 'plus';
        } else if (fitSwatchName === 'Petite') {
            fitName = 'petite';
        }
    }
    return fitName;
}

/**
 * This function updates the product context for Secret Sauce
 * @param {Object} $container product container
 * @param {string} context secret sauce initiation context
 */
function updateProductSetData($container, context) {
    if ($container && $container.length > 0) {
        let sizes = [];
        let availableSizes = [];
        $container.find('.scrollable-product-item .size-container .size-list .size-btn').each(function () {
            let size = $(this).attr('data-attr-value');
            if (size && size !== '') {
                sizes.push(size);
                if (!$(this).hasClass('not-available')) {
                    availableSizes.push(size);
                }
            }
        });
        const productId = $container.data('masterid').toString();
        const price = $container.find('.ssPrice').first().val();
        const salePrice = $container.find('.ssSalesPrice').first().val();
        const color = $container.find('.ssColor').first().val();
        const size = $container.find('.ssSize').first().val();
        const selectedFitName = getFitName();
        ssp('set', 'product', {
            productId,
            sizeType: selectedFitName,
            price,
            salePrice,
            color,
            sizes: sizes,
            availableSizes: availableSizes,
            size
        });
        if (context && context === 'variationUpdate') {
            startFP();
        }
    }
}


/**
 * This function sets the product context for Secret Sauce
 * @param {string} context secret sauce initiation context
 */
function productContext(context, container) {
    // skip call for gift cards
    const $giftCardAmount = $('input[name="giftcert_amount"]');
    if ($giftCardAmount && $giftCardAmount.length > 0) {
        // If we are on gift card, do not fire secretsauce
        return;
    }

    const isMaster = 'true';
    const selectedFitName = getFitName();
    if (context === 'load') {
        const pid = container[0].dataset.masterid;
        const price = getPrice(container[0]);
        const salePrice = getSalePrice(container[0]);
        let useSale = true;
        if (price === salePrice) {
            useSale = false;
        }
        if (container[0].closest('.set-items')) {
            $('.set-items .product-detail.product-wrapper').each(function () {
                let $container = $(this);
                updateProductSetData($container, context);
            });
            return;
        }

        if (isMaster === 'true') {
            if (useSale) {
                ssp('set', 'product', {
                    productId: pid,
                    sizeType: selectedFitName,
                    price,
                    salePrice,
                    color: getSelectedColor(container[0]),
                    sizes: getSizes(container[0]),
                    availableSizes: getAvailableSizes(container[0])
                });
            } else {
                ssp('set', 'product', {
                    productId: pid,
                    sizeType: selectedFitName,
                    price,
                    color: getSelectedColor(container[0]),
                    sizes: getSizes(container[0]),
                    availableSizes: getAvailableSizes(container[0])
                });
            }
        } else if (useSale) {
            ssp('set', 'product', {
                productId: $('#ssMasterPID').val(),
                variantId: getVariant(),
                sizeType: selectedFitName,
                price,
                salePrice,
                color: getSelectedColor(container[0]),
                sizes: getSizes(container[0]),
                availableSizes: getAvailableSizes(container[0]),
                size: getSelectedSize(container[0])
            });
        } else {
            ssp('set', 'product', {
                productId: $('#ssMasterPID').val(),
                variantId: getVariant(),
                sizeType: selectedFitName,
                price,
                color: getSelectedColor(container[0]),
                sizes: getSizes(container[0]),
                availableSizes: getAvailableSizes(container[0]),
                size: getSelectedSize(container[0])
            });
        }
    }
    if (context === 'color') {
        const pid = container.dataset.masterid;
        const price = getPrice(container);
        const salePrice = getSalePrice(container);
        let useSale = true;
        if (price === salePrice) {
            useSale = false;
        }
        if (isMaster === 'true') {
            if (useSale) {
                ssp('set', 'product', {
                    productId: pid,
                    color: getSelectedColor(container),
                    sizes: getSizes(container),
                    sizeType: selectedFitName,
                    availableSizes: getAvailableSizes(container),
                    price,
                    salePrice
                });
            } else {
                ssp('set', 'product', {
                    productId: pid,
                    color: getSelectedColor(container),
                    sizes: getSizes(container),
                    sizeType: selectedFitName,
                    availableSizes: getAvailableSizes(container),
                    price
                });
            }
        } else if (useSale) {
            ssp('set', 'product', {
                productId: $('#ssMasterPID').val(),
                variantId: getVariant(),
                color: getSelectedColor(container),
                sizes: getSizes(container),
                availableSizes: getAvailableSizes(container),
                price,
                salePrice
            });
        } else {
            ssp('set', 'product', {
                productId: $('#ssMasterPID').val(),
                variantId: getVariant(),
                color: getSelectedColor(container),
                sizes: getSizes(container),
                availableSizes: getAvailableSizes(container),
                price
            });
        }
    }
    if (context === 'size') {
        const pid = container.dataset.masterid;
        const price = getPrice(container);
        if (isMaster === 'true') {
            ssp('set', 'product', {
                productId: pid,
                color: getSelectedColor(container),
                sizes: getSizes(container),
                availableSizes: getAvailableSizes(container),
                size: getSelectedSize(container),
                price
            });
        } else {
            ssp('set', 'product', {
                productId: $('#ssMasterPID').val(),
                variantId: getVariant(),
                color: getSelectedColor(container),
                sizes: getSizes(container),
                availableSizes: getAvailableSizes(container),
                size: getSelectedSize(container),
                price
            });
        }
    }
}

/**
 * This function initiates Secret Sauce based on product's color change
 * @param {Object} container product container
 */
function handleColor(container) {
    const attribute = queryFirst('.ssColor', container);
    if (attribute && attribute.value !== null || 'unknown') {
        productContext('color', container);
    }
}

/**
 * This function initiates Secret Sauce based on product's size change
 * @param {Object} container product container
 */
function handleSize(container) {
    const attribute = queryFirst('.ssSize', container);
    if (attribute && attribute.value !== 'unknown' || null) {
        productContext('size', container);
    }
}

/**
 * This function returns page bread crumb
 * @return {string} returns page bread crumb
 */
function getCrumbs() {
    let crumb = '';
    $('.breadcrumb .breadcrumb-item').each(function () {
        if (crumb === '') {
            crumb = $(this).html;
        } else {
            crumb = crumb + ' > ' + $(this).html;
        }
    });
    return crumb;
}

/**
 * This function fires at the beginning
 * To prevent looping of the change event, which fires an ajax call which reloads everything, then concludes
 * with calling the predictor again, we need to setup some way to track, hence the use of a trigger.
 * @param {Object} container product container
 */
function prediction(container) {
    // The subscription seems to only allow itself to be set once.
    // i.e. future subscription handlers are discarded.
    // References to container will revert to the initial subscription,
    // which won't always be what we were looking for.
    // So I'm attaching the container to the function itself.
    prediction.container = container;

    ssp('subscribe', 'prediction', function (event) {
        queryAll('.product-detail.product-wrapper[data-masterid="' + event.productId + '"]').forEach(productContainer => {
            //check for an instance of the trigger
            const trigger = productContainer.getAttribute('data-sstrigger-first-time');
            if (trigger === 'true' && event.service === 'fitpredictor' && event.size) {
                const sizeOption = $('.size-container .size-list .size-btn[data-attr-value="' + event.size + '"]', productContainer);
                if (sizeOption) {
                    sizeOption.trigger('click');
                }
                productContainer.setAttribute('data-sstrigger-first-time', 'false');
                $('body').trigger('fitPredictorSizeSelected', productContainer);
            }
        });
    });
}

/**
 * This function sets Add to Cart context for Secret Sauce
 * @param {Object} container product container
 * @return {boolean} returns status if or not add to cart context is set for secret sauce
 */
function ssAddToCart(container) {
    if (ssp) {
        const { pid, masterid } = container.dataset;
        ssp('track', 'addVariantToCart', {
            variantId: pid,
            productId: masterid,
            quantity: 1
        });
        return true;
    }
    return false;
}

/**
 * This function sets flag for Secret Sauce initiation
 * @param {Object} container product's container
 */
function setupTrigger(container) {
    container.attr('data-sstrigger-first-time', 'true');
}

/**
 * This function sets all the context for Secret Sauce initiation and triggers Secret Sauce services
 * @param {Object} container product's container
 */
function initializeDom(container) {
    siteContext();
    custContext();
    let pt = 'pdp';
    if (container[0].closest('.search-results-container')) {
        pt = 'cat';
    } else if (container[0].closest('.product-set-detail')) {
        pt = 'pdp-set';
    } else if (hasClass(container[0], 'product-quickview')) {
        pt = 'quickview';
    }
    pageContext(pt);
    productContext('load', container);
    startFP();
    setupTrigger(container);
    prediction(container);
}

/**
 * This function initiates Secret Sauce
 * @param {Object} container product's container
 */
function sauce(container) {
    initializeDom(container);
}

module.exports = Object.entries({
    handleColor,
    handleSize,
    prediction,
    sauce,
    ssAddToCart,
    startFP,
    startOM,
    startSF,
    updateProductData,
    updateProductSetData
}).reduce((exported, [alias, method]) => {
    exported[alias] = featureSwitch(method);
    return exported;
}, {});
