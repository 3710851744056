'use strict';
import cart, { updateApproachingDiscounts } from '../cart/cart';
import { formatMessage, appendToUrl } from '../util';
import { queryFirst, removeClass, addClass, emptyCart, hasClass } from '../domUtil';
import { trackCart } from './etmc';
import { ssAddToCart } from 'fitpredictor/product/secretsauce';

const $body = $('body');
const $miniCart = $('.minicart');
const $miniCartDialog = $('#minicart');
const $miniCartContent = $miniCartDialog.find('.mini-cart-container');
const $miniCartQuantity = $('.minicart-quantity');
const $miniCartLink = $miniCart.find('.minicart-link');
const mql = window.matchMedia('(min-width: 1024px)');
const isMcEnable = $('#mcEnableAnalytics').val();
let isMiniCartUpdating = true;

/**
 * Updates mini cart title and close button with latest quantity.
 */
const updateTitle = () => {
    const labelText = formatMessage($miniCartContent.data('title-label'), $miniCartQuantity.text());
    const closeText = formatMessage($miniCartContent.data('close-label'), labelText);

    const labelElem = queryFirst('#minicart-label');
    const closeBtn = queryFirst('#minicart .close-current-modal');

    if (labelElem) {
        labelElem.textContent = labelText;
    }

    if (closeBtn) {
        closeBtn.ariaLabel = closeText;
    }
};

const updateMiniCartIcon = () => {
    const countVal = parseInt($miniCartQuantity.text(), 10);
    const iconToteActive = queryFirst('.icon-tote-active');
    const iconTote = queryFirst('.icon-tote');
    const hiddenClass = 'd-none';

    $miniCartQuantity.text(countVal);

    if (countVal) {
        removeClass(iconToteActive, hiddenClass);

        if (!hasClass(iconTote, hiddenClass)) {
            addClass(iconTote, hiddenClass);
        }
    } else {
        removeClass(iconTote, hiddenClass);

        if (!hasClass(iconToteActive, hiddenClass)) {
            addClass(iconToteActive, hiddenClass);
        }
    }
};
/**
 * Displays mini cart error message
 * @param {string} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = `<p class="cart-error-message">${message}</p>`;
    $('.cart-error')
        .empty()
        .append(errorHtml);
}

/**
 * Handle Minicart Recommendation functionality
 */
const initRecommendation = () => {

    function getAddToCartUrl() {
        return $('.add-to-cart-url').val();
    }

    /**
     * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
     * @param {string} response - ajax response from clicking the add to cart button
     */
    function handlePostCartAdd(response) {
        $('.minicart').trigger('count:update', response);
        if (!response.error) {
            return;
        }

        if (response.displayModal) {
            let addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog');

            if (!addToCartWarningDialog) {
                const isOnCartPage = $('.cart-container').length > 0;
                okbtn = $('.cart-container').attr('data-ok');
                closebtn = $('.cart-container').attr('data-close');
                review = $('.cart-container').attr('data-review');
                const fragment = renderFragment(dialogTemplate({
                    buttons: isOnCartPage ? [{ text: okbtn, primary: true }] : [{ text: closebtn }, { text: review, primary: true, link: response.redirectLink }],
                    modalContentHeading: response.messageHeading,
                    modalContentBody: isOnCartPage ? response.messageBodyOnCart : response.messageBodyOffCart,
                    id: 'add-to-cart-warning-dialog'
                }));

                document.body.appendChild(fragment);
                addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog');
            }

            $(addToCartWarningDialog).modal('show');
        } else {
            var messageType = response.error ? 'alert-danger' : 'alert-success';
            // show add to cart toast
            const $addtocartmessages = $('.add-to-cart-messages');
            $addtocartmessages.remove();
            if ($addtocartmessages.length === 0) {
                $body.append('<div class="add-to-cart-messages"></div>');
            }

            $addtocartmessages.append('<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">' + response.message + '</div>');

            setTimeout(function () {
                $('.add-to-basket-alert').remove();
            }, 5000);
        }
    }

    // add logic for ATC and remove recommendation
    $(document).off('click', '.product-recommendation-tile button.add-to-cart').on('click', '.product-recommendation-tile button.add-to-cart', function () {
        var addToCartUrl;
        var pid;

        const storeLocatoreContainer = queryFirst('.store-locator-container');
        const ispuAddToCart = storeLocatoreContainer && queryFirst('.add-to-cart', storeLocatoreContainer);
        const ispuModalContainer = $(this)
            .closest('.product-detail')
            .find('.lp-slideout-modal[id*="pick-up-in-store"]');
        if (ispuAddToCart) {
            ispuAddToCart.disabled = true;
        }
        let isSet = false;
        let triggerSSAddToCart = true;
        const isFitPredictorEnabled = !!queryFirst('#fitPredictorEnabled');
        var $productContainer = $(this).closest('.product-detail');

        $body.trigger('product:beforeAddToCart', this);

        pid = $('.product-recommendation-tile').attr('data-pid');

        if (!$productContainer.length) {
            $productContainer = $(this)
                .closest('.quick-view-dialog')
                .find('.product-detail');
        }

        addToCartUrl = getAddToCartUrl();

        var form = {
            pid: pid,
        };

        $(this).trigger('updateAddToCartFormData', form);

        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (isSet) {
                        $('#productSetModal').modal('hide');
                    }

                    if (ispuAddToCart) {
                        ispuAddToCart.disabled = false;
                        ispuModalContainer.modal('hide');
                    }

                    handlePostCartAdd(data);

                    if (triggerSSAddToCart && isFitPredictorEnabled) {
                        ssAddToCart($productContainer[0]);
                    }

                    $.spinner().stop();
                    $body.trigger('product:afterAddToCart', data);
                },
                error: function () {
                    $.spinner().stop();
                    if (ispuAddToCart) {
                        ispuAddToCart.disabled = false;
                    }
                }
            });
        }
    });
    $(document).off('click', '.minicart-product-recommendation .close-icon').on('click', '.minicart-product-recommendation .close-icon', function () {
        const $minicartRecomContainer = $('.minicart-recommendation-container');
        $minicartRecomContainer.remove();
    });
}

/**
 * Initializes the event handlers
 */
function init() {
    cart();

    $miniCart.on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $miniCart.find('.minicart-quantity').text(count.quantityTotal);
            updateMiniCartIcon();
            updateTitle();
            $miniCart.find('.minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $miniCartLink.on('touchstart click', function (e, keepMiniCartOpen) {
        const url = $miniCart.data('action-url');
        const count = parseInt($miniCart.find('.minicart-quantity').text(), 10);
        const emptyCartContent = queryFirst('.empty-cart-content');
        if (this.dataset.sflEnabled === 'true' && !count) {
            e.stopImmediatePropagation();
            e.preventDefault();
            location.href = this.dataset.actionUrl;
        }
        else {
            if (!$miniCartDialog.hasClass('.show')) {
                if (isMiniCartUpdating && !$miniCartDialog.attr('aria-busy')) {
                    $miniCartDialog.attr('aria-busy', true);
                    $miniCartDialog
                        .find('.modal-dialog')
                        .spinner()
                        .start();
                    /* Empting data target to avoid auto closure of minicart modal
                    * on adding product from minicart
                    */
                    if (keepMiniCartOpen) {
                        $miniCartLink.attr('data-target', '');
                    }
                    $.get(url, function (data) {
                        if ($(data).find('.product-line-item').length > 0) {
                            $miniCartContent.empty().append(data);

                            // init minicart recommendation container
                            const $minicartRecomContainer = $('.minicart-recommendation-container');
                            if ($minicartRecomContainer.find('.product-recommendation-tile').length) {
                                initRecommendation();
                            } else {
                                $minicartRecomContainer.remove();
                            }
                        } else {
                            $miniCartContent.empty().append(data);
                            $miniCart.trigger('count:update', [
                                {
                                    quantityTotal: 0,
                                    minicartCountOfItems: $('.minicart-link').attr('data-zero-count')
                                }
                            ]);
                            removeClass(emptyCartContent, 'd-none');
                            $miniCartContent.find('.cart.container').addClass('d-none');
                            updateTitle();
                        }
                        $miniCartContent.closest('.modal-content').scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                        isMiniCartUpdating = false;
                        $miniCartDialog.removeAttr('aria-busy');

                        // Adding back data target to mini cart link
                        if (keepMiniCartOpen) {
                            $miniCartLink.attr('data-target', '#minicart');
                        }
                        $.spinner().stop();
                    }).fail(function () {
                        $miniCartDialog.removeAttr('aria-busy');
                        $.spinner().stop();
                    });
                }
            }
        }
        const minicart = queryFirst('#minicart .cart');
        if (count === 0) {
            removeClass(emptyCartContent, 'd-none');
            if (minicart) {
                minicart.innerHTML = '';
            }
        } else {
            addClass(emptyCartContent, 'd-none');
        }

        const windowTest = w => {
            if (w.matches) {
                return;
            }
            if (hasClass(queryFirst('.main-menu'), 'in')) {
                queryFirst('.navbar-toggler').click();
            }
        };
        windowTest(mql);
    });
    $body.on('cart:update', () => {
        isMiniCartUpdating = true;
        updateTitle();
        updateMiniCartIcon();
    });

    // After a product is added to cart
    $body.on('product:afterAddToCart', (e, data) => {
        isMiniCartUpdating = true;
        updateTitle();

        if (isMcEnable === 'true') {
            // update Marketing Cloud Analytics trackCart event
            const { marketingCloudAnalyticsData: mcData } = data;
            if (mcData) trackCart(mcData);
        }

        if (!queryFirst('.cart-container') && data && !data.error) {
            // Use jQuery so GA binding doesn't fire
            // pass boolean value if minicart modal is already open at the time of Add to cart
            $('.minicart-link').trigger('click', $miniCartDialog.hasClass('show'));
        }
    });

    $(document).on('click', '.remove-product-btn', function (e) {
        e.preventDefault();
        let pid = $('.product-recommendation-tile').attr('data-pid');
        let uuid = $(this).data('uuid');
        let url = $(this).data('action');
        const { name } = this.dataset;
        var urlParams = {
            pid: $(this).data('pid'),
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);
        $miniCartContent
            .find('.modal-dialog')
            .spinner()
            .start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    emptyCart(data.basket.resources.emptyCartMsg);
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    if (!data.basket.inStoreProductLineItems.length) {
                        const hiddenClass = 'd-none';
                        addClass(queryFirst('.mini-cart-store-title'), hiddenClass);
                        addClass(queryFirst('.cart-pickup-products'), hiddenClass);
                    }
                }
                $('.minicart-link').attr({
                    'aria-label': data.basket.resources.minicartCountOfItems,
                    title: data.basket.resources.minicartCountOfItems
                });
                const subTotalElement = queryFirst('.minicart-footer .price-value');
                const { totals, valid, numItems } = data.basket;
                if (subTotalElement) {
                    subTotalElement.textContent = totals.subTotal;
                }

                if (!valid.error) {
                    const cartError = queryFirst('.cart-error');

                    if (cartError) cartError.textContent = '';
                } else {
                    createErrorNotification(valid.message);
                }

                if (numItems === 0) {
                    const emptyCartContent = queryFirst('.empty-cart-content');
                    removeClass(emptyCartContent, 'd-none');
                    $miniCartContent.find('.cart.container').addClass('d-none');
                }
                const { bonusLineItems, approachingDiscounts } = data.basket;
                if (bonusLineItems && bonusLineItems.maxPids) {
                    const quantityEl = queryFirst('.gwp-option-bonus .item-quantity .attribute-value', $miniCart[0]);
                    const cartGwpQuantityEl = queryFirst('.cart-container .item-description');
                    if (quantityEl) {
                        quantityEl.textContent = bonusLineItems.maxPids;
                    }

                    if (cartGwpQuantityEl) {
                        cartGwpQuantityEl.textContent = cartGwpQuantityEl.textContent.replace(/\d/, bonusLineItems.maxPids);
                    }
                }
                $miniCartQuantity.empty().append(data.basket.numItems);
                updateMiniCartIcon();

                $body.trigger('cart:update');
                $('.cart-container, .mini-cart-container').trigger('cart:updateTotals', data);
                $body.trigger('removeFromMinicart', name);
                updateApproachingDiscounts(approachingDiscounts);

                if (isMcEnable === 'true') {
                    // update Marketing Cloud Analytics trackCart event
                    trackCart(data.mcAnalyticsArray);
                }

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $.spinner().stop();
                }
            }
        });
    });

    $miniCartDialog.on('show.bs.modal', function (e) {
        addClass(queryFirst('body'), 'modal-open');
        const quickBuy = $('.quick-buy-add-toast');
        if (window.matchMedia("(max-width: 1023px)").matches && quickBuy.attr('is-quick-buy') === 'true') {
            e.preventDefault();
            quickBuy.removeClass('d-none');
            setTimeout(
                function () {
                    quickBuy.addClass('d-none')
                }, 5000);
            $('.quick-buy-add-toast').attr('is-quick-buy', false);
            removeClass(queryFirst('body'), 'modal-open');
        }
    });
}

export default init;
