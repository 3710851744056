'use strict';

import { queryFirst, queryAll } from './domUtil';

const phInfoPopover = queryFirst('.ph-number-info-popover .content-asset');
const fpoInfoPopover = queryFirst('.fpo-info-popover .content-asset');
const checkoutBenefitsInfoPopover = queryFirst('.checkout-benefits-info .checkout-benefits-info-content');
const cvvInfoPopover = queryFirst('.cvv-info-popover .cvv-info-popover-content');
const giftCardInfoPopover = queryFirst('.gift-card-number-info-popover .content-asset');
const birthdayInfoPopover = queryFirst('.birthday-info-popover .content-asset');
const deliveryFeeInfoPopover = queryFirst('.delivery-fee-info-popover .content-asset');
const boltSignoutMessage = queryFirst('.bolt-logout-button .bolt-sign-out');

const initPopover = (selector, ...options) => $(selector).popover(Object.assign({}, ...options));

const POPOVER_CLICK_BOTTOM = {
    trigger: 'click',
    placement: 'bottom',
    html: true
};

initPopover('.ph-number-info', POPOVER_CLICK_BOTTOM, {
    content: phInfoPopover
});

const popoverIcons = queryAll('.loyalty-tooltip-icon');
for (let tooltipIcon of popoverIcons) {
    const popContent = queryFirst('.loyalty-tooltip-popover', tooltipIcon.parentElement);

    $(tooltipIcon).popover({
        trigger: 'click',
        placement: 'auto',
        html: true,
        content: popContent
    });
}

initPopover('.birthday-info', POPOVER_CLICK_BOTTOM, {
    content: birthdayInfoPopover
});

initPopover('.fpo-info', POPOVER_CLICK_BOTTOM, {
    content: fpoInfoPopover
});

initPopover('.checkout-benefits-info', POPOVER_CLICK_BOTTOM, {
    container: '.new-customers',
    offset: '160 0',
    content: checkoutBenefitsInfoPopover
});

initPopover('.cvv-info', POPOVER_CLICK_BOTTOM, {
    content: cvvInfoPopover
});

initPopover('.gift-card-number-info', POPOVER_CLICK_BOTTOM, {
    content: giftCardInfoPopover
});

initPopover('.delivery-fee-info', POPOVER_CLICK_BOTTOM, {
    content: deliveryFeeInfoPopover
});
initPopover('.bolt-logout-button-container', POPOVER_CLICK_BOTTOM, {
    content: boltSignoutMessage
});

const promoInfoButtons = queryAll('.promotions-info');
for (let button of promoInfoButtons) {
    const popoverContent = queryFirst('.promotions-info-popover .promotions-info-popover-content', button.parentElement);

    $(button).popover({
        trigger: 'click',
        placement: 'bottom',
        html: true,
        content: popoverContent
    });
}

$('[data-toggle="popover"]').on('shown.bs.popover', function (e) {
    var target = e.target;
    $(document).one('click', function (clickEvent) {
        var clicked = clickEvent.target;
        if (clicked != target && clicked.parentElement != target && $('.popover.show').length) {
            $(target).trigger('click');
        }
    });
});
