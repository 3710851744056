'use strict';

const { queryFirst, addClass, removeClass, hasClass, matchesBreakpoint } = require('../domUtil');
const { getNestedValue } = require('../util');
const { selectStyleMessageTmpl, ispuSelectStyleMessageTmpl } = require('../templates').productDetail;
const { EMPTY_STRING } = require('../constants');

/**
 * Function to check if shipping preferences view is enabled or not
 * @returns { boolean } - returns isEnabled flag value
 */
function isShippingPreferencesViewEnabled() {
    return getNestedValue(window, 'johnnyWasUtils.shippingPreferencesConfig.isEnabled') || false;
}


/**
 * This method manages Notify me container content
 * @param {Object} productData - Product data
 * @param {Object} productContainer - Product Container DOM element
 */
function handleNotifyMe(productData, productContainer) {
    const $body = $('body');
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const notifyContainer = queryFirst('.notify-me-container', productContainer);
    const notifyMeButton = queryFirst('.notify-me-btn', productContainer);
    const notifyMeDesc = queryFirst('.notify-me-desc', productContainer);
    const availabilityMessageEl = queryFirst('.product-availability', productContainer);
    const isPDPPage = queryFirst('.product-detail');
    const mainImageEle = queryFirst('.primary-images .carousel-item.active img', productContainer);
    const hiddenClass = 'd-none';
    const { available, isNotifyMeEnabled, id, forceOutOfStock, imageData } = productData;
    if (!available && isNotifyMeEnabled && !forceOutOfStock) {
        addClass(addToCartSection, hiddenClass);
        if (availabilityMessageEl) {
            addClass(availabilityMessageEl, hiddenClass);
        }
        removeClass([notifyMeDesc, notifyMeButton], hiddenClass);
        const notifyImageElement = queryFirst('.notify-product-img img', notifyContainer);
        if (imageData && notifyImageElement) {
            const { alt, url, srcset, src } = isPDPPage ? mainImageEle : imageData[0];
            const imageHasLoaded = hasClass(notifyImageElement, 'lz-loaded');

            notifyImageElement.setAttribute(imageHasLoaded ? 'src' : 'data-src', isPDPPage ? src : url);
            notifyImageElement.setAttribute(imageHasLoaded ? 'srcset' : 'data-srcset', srcset);
            notifyImageElement.setAttribute('alt', alt);
        }
        const notifySize = queryFirst('.notify-size', notifyContainer);
        const notifySizeSeperator = queryFirst('.size-seperator', notifyContainer);
        const notifySelectedSize = queryFirst('.selected-size', notifyContainer);
        queryFirst('.size-label', notifySize).textContent = queryFirst('.size-display-name', productContainer).value;
        queryFirst('.selected-size', notifySize).textContent = queryFirst('.size-btn.selected', productContainer).dataset.attrValue;
        removeClass(notifySizeSeperator, 'd-none');
        removeClass(notifySelectedSize, 'd-none');
        if (id) {
            queryFirst('.notify-pid', notifyContainer).value = id;
        }
        const custEmail = (document.getElementById('notifyEmail') || {}).value;
        if (custEmail) {
            queryFirst('.notify-email', notifyContainer).value = custEmail;
        }
        removeClass(queryFirst('.notify-me-desc', notifyContainer), hiddenClass);
        removeClass(queryFirst('.notify-form', notifyContainer), hiddenClass);
        addClass(queryFirst('.notify-me-confirm', notifyContainer), hiddenClass);
        addClass(queryFirst('.footer-close-link', notifyContainer), hiddenClass);

        $body.trigger('product:notifyMeShown', productContainer);
    } else {
        removeClass(addToCartSection, hiddenClass);
        if (availabilityMessageEl) {
            removeClass(availabilityMessageEl, hiddenClass);
        }
        addClass([notifyMeDesc, notifyMeButton], hiddenClass);

        $body.trigger('product:notifyMeHidden', productContainer);
    }
}

/**
 * Function to toggle select color or size info
 * @param {HTMLElement} productContainer - current product element
 */
function toggleSelectSizeInfo(productContainer) {
    const selectedSizeEl = queryFirst('.size-btn.selected', productContainer);
    const availabilityMessageEl = queryFirst('.select-size-message', productContainer);
    if (availabilityMessageEl) {
        if (!selectedSizeEl) {
            removeClass(availabilityMessageEl, 'd-none');
            availabilityMessageEl.innerHTML = selectStyleMessageTmpl(availabilityMessageEl.dataset.selectStylesMessage);
        }
    }

    if (isShippingPreferencesViewEnabled()) {
        const ispuAvailabilityMessageEl = queryFirst('.ispu-preference-container .select-size-info-msg', productContainer);
        if (ispuAvailabilityMessageEl) {
            const messages = getNestedValue(window, 'johnnyWasUtils.shippingPreferencesConfig.messages') || {};
            const { selectStylesMessage } = messages;
            if (!selectedSizeEl && selectStylesMessage) {
                ispuAvailabilityMessageEl.innerHTML = ispuSelectStyleMessageTmpl(selectStylesMessage);
            }
        }
    }
}
/**
 * Helper method to check if product is on early access or not
 * @param {Object|String} earlyAccess - product | early access date
 * @return {Boolean} true, if early access product or false for not
 */
function isEarlyAccessElement(earlyAccess) {
    let isEarlyAccessItem = false;
    const earlyAccessDate = earlyAccess && earlyAccess.earlyAccessUntilDate || earlyAccess;
    if (earlyAccessDate && earlyAccessDate !== 'false') {
        if (new Date(earlyAccessDate).getTime() > new Date().getTime()) {
            isEarlyAccessItem = true;
        }
    }
    return isEarlyAccessItem;
}

const updatePLPTileHeight = (element) => {
    let cards = $('.tile-img-link');
    let carouselImageHeight = cards;

    if (element) {
        carouselImageHeight = $('.carousel-inner');
    }

    // Find the maximum height among the cards
    let maxHeight = Math.max.apply(null, carouselImageHeight.map(function () {
        return $(this).height();
    }).get());

    // Set the same height for all cards
    cards.height(maxHeight);
};


module.exports = {
    toggleSelectSizeInfo: toggleSelectSizeInfo,
    isEarlyAccessElement: isEarlyAccessElement,
    isShippingPreferencesViewEnabled: isShippingPreferencesViewEnabled,
    handleNotifyMe: handleNotifyMe,
    updatePLPTileHeight: updatePLPTileHeight
};

