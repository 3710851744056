'use strict';

/**
 * Ovverides Amazon Pay Button Styles on cart
 * @param {boolean} isCartPage Checks if request is coming from cart page
 */
const updateAmazonPayButton = (isCartPage) => {
    const $amazonPayWrapper = isCartPage ? $('div[amazonpay-type="cart"]') : $('div[amazonpay-type="additionalPayButton"]');
    const shadowRoot = $amazonPayWrapper[0].shadowRoot;
    const style = document.createElement('style');
    style.innerHTML = `
    .amazonpay-button-view1-gold {
        background: none!important;
        border-color: #19183e!important;
        text-align: center;
        height: 48px;
        border-radius: 0;
    }

    .amazonpay-button-view1-gold .amazonpay-button-logo {
        display: inline-block;
    }

    .amazonpay-button-chevrons,
    .amazonpay-button-sandbox-logo,
    .amazonpay-button-view2,
    .amazonpay-button-view3 {
        display: none!important
    }`;
    shadowRoot.appendChild(style);
}

module.exports = {
    updateAmazonPayButton
};