'use strict';

const { queryFirst, addClass, removeClass, hasClass } = require('../../domUtil');
const { getNestedValue } = require('../../util');
const {
    PREFERENCE_SELECTOR, ISPU_STORE_NAME_CLASS, SHIPPING_PREFERENCES_CONTAINER_SELECTOR,
    ISPU_RADIO_SELECTOR, SHIP_TO_ADDRESS_RADIO_SELECTOR, CHANGE_STORE_LINK_SELECTOR,
    ISPU_PREFERENCE_CONTENT_SELECTOR, SHIP_TO_ADDRESS_CONTENT_SELECTOR, DISABLED_CLASS,
    SHIP_TO_RADIO_VALUE, RADIO_BUTTON_LABEL_SELECTOR, SHIP_TO_LOW_INVENTORY_CLASS,
    STORE_AVAILABILITY_MESSAGE_SELECTOR, INVENTORY_MSG_SELECTOR, ISPU_RADIO_VALUE, AVAILABILITY_MSG_SELECTOR, LOW_STOCK_ISPU_SELECTOR
} = require('./constants');
const { HIDDEN_CLASS } = require('../../constants');
const { handleNotifyMe } = require('../../product/helper');

/**
 * Function to get shipping preferences container within product container
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {Object} returns the target as a DOM node
 */
function getChangeStoreLinkEl(productContainerEl) {
    return queryFirst(CHANGE_STORE_LINK_SELECTOR, productContainerEl);
}

/**
 * Function to get shipping preferences container within product container
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {Object} returns the target as a DOM node
 */
function getShippingPreferencesContainerEl(productContainerEl) {
    return queryFirst(SHIPPING_PREFERENCES_CONTAINER_SELECTOR, productContainerEl);
}

/**
 * Function to get ISPU radio button within product container
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {Object} returns the target as a DOM node
 */
function getISPUradioButtonEl(productContainerEl) {
    return queryFirst(ISPU_RADIO_SELECTOR, productContainerEl);
}

/**
 * Function to get Ship To section's content within product container
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {Object} returns the target as a DOM node
 */
function getShipToAddressContentEl(productContainerEl) {
    return queryFirst(SHIP_TO_ADDRESS_CONTENT_SELECTOR, productContainerEl);
}

/**
 * Function to get ship to address radio button within product container
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {Object} returns the target as a DOM node
 */
function getShipToAddressradioButtonEl(productContainerEl) {
    return queryFirst(SHIP_TO_ADDRESS_RADIO_SELECTOR, productContainerEl);
}

/**
 * Function to get ISPU content radio button within product container
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {Object} returns the target as a DOM node
 */
function getISPUPreferenceContentEl(productContainerEl) {
    return queryFirst(ISPU_PREFERENCE_CONTENT_SELECTOR, productContainerEl);
}

/**
 * Function to get selected radio button preference within product container
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {Object} returns the target as a DOM node
 */
function getSelectedPreferenceEl(productContainerEl) {
    return queryFirst(PREFERENCE_SELECTOR, productContainerEl);
}

/**
 * Function to get add a ISPU required class to make ISPU - ADD TO CART work
 * @param {HTMLElement} productContainerEl - Product container Element
 * @param {HTMLElement?} storeNameEl - optional store name element
 */
function addStoreNameClass(productContainerEl, storeNameEl) {
    storeNameEl = storeNameEl || queryFirst('.store-detail .name', productContainerEl);
    if (storeNameEl) {
        addClass(storeNameEl, ISPU_STORE_NAME_CLASS);
    }
}

/**
 * Function to get remove a ISPU required class to make SHIP TO - ADD TO CART work
 * @param {HTMLElement} productContainerEl - Product container Element
 * @param {HTMLElement?} storeNameEl - optional store name element
 */
function removeStoreNameClass(productContainerEl, storeNameEl) {
    storeNameEl = storeNameEl || queryFirst('.store-detail .name', productContainerEl);
    if (storeNameEl) {
        removeClass(storeNameEl, ISPU_STORE_NAME_CLASS);
    }
}

/**
 * Function to check if a size is selected or not
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {Object} returns the target as a DOM node
 */
function getSelectedSizeEl(productContainerEl) {
    return queryFirst('.size-btn.selected', productContainerEl);
}

/**
 * Function to update Visibility Of Low Inventory Msg for ISPU and Ship To an address
 * @param {HTMLElement} productContainerEl - Product container Element
 */
function updateVisibilityOfLowInventoryMsg(productContainerEl) {
    const ispuLowStockEl = queryFirst(LOW_STOCK_ISPU_SELECTOR, productContainerEl);
    const availabilityElement = queryFirst(AVAILABILITY_MSG_SELECTOR, productContainerEl);
    const ispuLowInventory = queryFirst(STORE_AVAILABILITY_MESSAGE_SELECTOR);
    const ispuLowInventoryMsg = queryFirst(INVENTORY_MSG_SELECTOR, productContainerEl);
    const ispuRadioButtonEl = getISPUradioButtonEl(productContainerEl);
    const notifyMeButton = queryFirst('.notify-me-btn', productContainerEl);
    const notifyMeDesc = queryFirst('.notify-me-desc', productContainerEl);
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainerEl);
    const selectedSizeElement = queryFirst('.size-btn.selected', productContainerEl);
    // If ISPU enabled, setting the message only after ispu inventory is created
    // one of the radio button is selected
    if (ispuRadioButtonEl && window.ispuProductInventory) {
        if (ispuRadioButtonEl.checked) {
            removeClass(addToCartSection, HIDDEN_CLASS);
            addClass([notifyMeDesc, notifyMeButton], HIDDEN_CLASS);
            removeClass(selectedSizeElement, 'not-available')
            addClass(availabilityElement, HIDDEN_CLASS);
            if (ispuLowInventoryMsg) {
                removeClass([ispuLowInventory, ispuLowStockEl], HIDDEN_CLASS);
            }
            else {
                addClass(ispuLowStockEl, HIDDEN_CLASS)
            }
        }
        else if (!ispuRadioButtonEl.disabled) {
            addClass([ispuLowInventory, ispuLowStockEl], HIDDEN_CLASS);
            removeClass(availabilityElement, HIDDEN_CLASS);
            if (selectedSizeElement) {
                const masterId = productContainerEl.dataset.masterid;
                const isSetPage = queryFirst('.custom-set-detail');
                const selectedSizeValue = selectedSizeElement ? selectedSizeElement.dataset.attrValue : '';
                const selectedColorElement = queryFirst('.color-attribute .swatch-circle.selected', productContainerEl);
                const selectedColorId = selectedColorElement.dataset.attrValue;
                const productInfo = window.productInfo[masterId];
                const variantGroupData = productInfo.variants[selectedColorId];
                const { sizes, images, formattedPrice } = variantGroupData;
                const selectedSizeData = sizes[selectedSizeValue];
                const { isNotifyMeEnabled, ID, forceOutOfStock } = selectedSizeData;
                const { variants } = isSetPage ? window.productInventory[masterId] : window.productInventory;
                const inventoryData = variants[ID];
                const { availabilityStatus } = inventoryData;
                if (availabilityStatus !== 'NOT_AVAILABLE') {
                    removeClass(selectedSizeElement, 'not-available');
                } else {
                    addClass(selectedSizeElement, 'not-available')
                }
                if (selectedSizeValue === '') {
                    addClass(availabilityElement, HIDDEN_CLASS);
                }
                const productData = {
                    available: !hasClass(selectedSizeElement, 'not-available'),
                    isNotifyMeEnabled,
                    id: ID,
                    formattedPrice,
                    forceOutOfStock,
                    imageData: images && images.ispu ? images.ispu : ''
                };
                handleNotifyMe(productData, productContainerEl);
            }
        }
    }
}
/**
 * Function to select ISPU radio button
 * @param {HTMLElement} productContainerEl - Product container Element
 * @param {HTMLElement} ispuRadioButtonEl - ISPU radio button element
 * @param {boolean} enableISPUSection - flag to remove fade out style to ispu section's content
 */
function selectISPURadioButton(productContainerEl, ispuRadioButtonEl, enableISPUSection) {
    ispuRadioButtonEl = ispuRadioButtonEl || getISPUradioButtonEl(productContainerEl);
    if (!ispuRadioButtonEl) return;

    ispuRadioButtonEl.disabled = false;
    removeClass(queryFirst(RADIO_BUTTON_LABEL_SELECTOR, ispuRadioButtonEl.parentElement), DISABLED_CLASS);

    ispuRadioButtonEl.checked = true;

    addStoreNameClass(productContainerEl);

    if (enableISPUSection) {
        const ispuContentEl = getISPUPreferenceContentEl(productContainerEl);
        removeClass(ispuContentEl, DISABLED_CLASS);
    }
}

/**
 * Function to disable & deselect Ship To radio button
 * @param {HTMLElement} productContainerEl - Product container Element
 * @param {HTMLElement} ispuRadioButtonEl - ISPU radio button element
 * @param {boolean} disableISPUSection - flag to add fade out style ispu section's content
 */
function disableISPURadioButton(productContainerEl, ispuRadioButtonEl, disableISPUSection) {
    ispuRadioButtonEl = ispuRadioButtonEl || getISPUradioButtonEl(productContainerEl);
    if (!ispuRadioButtonEl) return;

    ispuRadioButtonEl.disabled = true;
    addClass(queryFirst(RADIO_BUTTON_LABEL_SELECTOR, ispuRadioButtonEl.parentElement), DISABLED_CLASS);

    ispuRadioButtonEl.checked = false;

    removeStoreNameClass(productContainerEl);

    if (disableISPUSection) {
        const ispuContentEl = getISPUPreferenceContentEl(productContainerEl);
        addClass(ispuContentEl, DISABLED_CLASS);
    }
}

/**
 * Function to select Ship To radio button
 * @param {HTMLElement} productContainerEl - Product container Element
 * @param {HTMLElement} shipToRadioButtonEl - Ship to address radio button element
 * @param {boolean} enableShipToContent - flag to add fade out style shipTo section's content
 */
function selectShipToRadioButton(productContainerEl, shipToRadioButtonEl, enableShipToContent) {
    shipToRadioButtonEl = shipToRadioButtonEl || getShipToAddressradioButtonEl(productContainerEl);
    if (!shipToRadioButtonEl) return;

    shipToRadioButtonEl.disabled = false;
    removeClass(queryFirst(RADIO_BUTTON_LABEL_SELECTOR, shipToRadioButtonEl.parentElement), DISABLED_CLASS);

    shipToRadioButtonEl.checked = true;

    removeStoreNameClass(productContainerEl);

    if (enableShipToContent) {
        const shipToContentEl = getShipToAddressContentEl(productContainerEl);
        removeClass(shipToContentEl, DISABLED_CLASS);
    }
}

/**
 * Function to disable & deselect Ship To radio button
 * @param {HTMLElement} productContainerEl - Product container Element
 * @param {HTMLElement} shipToRadioButtonEl - Ship to address radio button element
 * @param {boolean} disableShipToContent - flag to remove fade out style from shipTo section's content
 */
function disableShipToRadioButton(productContainerEl, shipToRadioButtonEl, disableShipToContent) {
    shipToRadioButtonEl = shipToRadioButtonEl || getISPUradioButtonEl(productContainerEl);
    if (!shipToRadioButtonEl) return;

    shipToRadioButtonEl.disabled = true;
    addClass(queryFirst(RADIO_BUTTON_LABEL_SELECTOR, shipToRadioButtonEl.parentElement), DISABLED_CLASS);

    shipToRadioButtonEl.checked = false;

    if (disableShipToContent) {
        const shipToContentEl = getShipToAddressContentEl(productContainerEl);
        addClass(shipToContentEl, DISABLED_CLASS);
    }
}

/**
 * Function to get preferred shipping preference
 * @returns {string} - Preferred shipping preference value
 */
function getPreferedShippingPreference() {
    const shippingPreferencesConfig = getNestedValue(window, 'johnnyWasUtils.shippingPreferencesConfig') || {};
    const { activeStoreFilter } = shippingPreferencesConfig;
    return activeStoreFilter ? ISPU_RADIO_VALUE : SHIP_TO_RADIO_VALUE;
}

/**
 * Function to check if selected size is available for in-store pickup
 * @param {HTMLElement} productContainerEl - Product container Element
 * @returns {boolean} - ISPU availability for selected size
 */
function getAvailableForInStorePickup(productContainerEl) {
    const selectedSizeElement = getSelectedSizeEl(productContainerEl);
    const masterId = productContainerEl.dataset.masterid;
    const selectedSizeValue = selectedSizeElement ? selectedSizeElement.dataset.attrValue : '';
    const selectedColorElement = queryFirst('.color-attribute .swatch-circle.selected', productContainerEl);
    const selectedColorId = selectedColorElement.dataset.attrValue;
    const productInfo = window.productInfo[masterId];
    const variantGroupData = productInfo.variants[selectedColorId];
    const { sizes } = variantGroupData;
    const selectedSizeData = sizes[selectedSizeValue];
    return selectedSizeData.availableForInStorePickup;
}

module.exports = {
    getChangeStoreLinkEl,
    getSelectedPreferenceEl,
    getShippingPreferencesContainerEl,
    getISPUradioButtonEl,
    getShipToAddressradioButtonEl,
    getShipToAddressContentEl,
    getISPUPreferenceContentEl,
    getSelectedSizeEl,
    addStoreNameClass,
    removeStoreNameClass,
    selectISPURadioButton,
    disableISPURadioButton,
    selectShipToRadioButton,
    disableShipToRadioButton,
    getPreferedShippingPreference,
    updateVisibilityOfLowInventoryMsg,
    getAvailableForInStorePickup
};
