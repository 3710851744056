import { EventAccountRecognitionCheckPerformed } from './constant.js';
import { getISOCodeByLocale } from './util.js';
const { removeClass, queryFirst, addClass } = require('./domUtil');
const hiddenClass = 'd-none';

/**
 * This function creates the Bolt component from embed.js,
 * mount it on the page and renders the OTP modal to do authentication & authorization with Bolt
 * @param {string} customerEmail - input email
 * @returns {Promise} - the returned promise waits for the user to enter the 6 digis OTP code
 */
async function authorize(customerEmail) {
    const boltPublishableKey = $('.bolt-publishable-key').val();
    const locale = $('.bolt-locale').val();

    const boltEmbedded = Bolt(boltPublishableKey, {
        language: getISOCodeByLocale(locale)
    });

    const authorizationComponent = boltEmbedded.create('authorization_component', { style: { position: 'right' } });
    if (customerEmail != null) {
        const containerToMount = $('#email-guest').parent().get(0); // there is only 1 occurance of $('#email-guest')
        containerToMount.classList.add('containerToMount');
        await authorizationComponent.mount('.containerToMount'); // mount on the div container otherwise the iframe won't render
        return authorizationComponent.authorize({ email: customerEmail });
    }
    // LP customization - Removing detect bolt auto login
    return false;
}

/**
 * Log the user into their bolt account
 * @param {string} email - input email
 * @returns {Promise} The returned promise to fetch account details
 */
async function login(email) {
    const authorizeResp = await authorize(email);
    $('.submit-customer').removeAttr('disabled'); // enable checkout button after OTP modal is rendered
    if (!authorizeResp) {
        // If the user closed the OTP modal then change the payment method to CREDIT_CARD if it is BOLT_PAY 
        const checkoutContainer = queryFirst('.data-checkout-stage');
        if (checkoutContainer && ($('.payment-information').attr('data-payment-method-id') === 'BOLT_PAY')) {
            const creditCardContent = queryFirst('.credit-card-content');
            creditCardContent.id = 'credit-card-content';
            queryFirst('[data-method-id="CREDIT_CARD"] a').click();
            removeClass(queryFirst('.credit-card-tab-list'), 'payment-options-hide');
            addClass(queryFirst('.bolt-pay-tab-list'), hiddenClass);
        }
        $('body').trigger('bolt:closeOtpModal');
        return;
    }
    const OAuthResp = await authenticateUserWithCode(
        authorizeResp.authorizationCode,
        authorizeResp.scope
    );
    return getAccountDetails(OAuthResp.accessToken); // eslint-disable-line consistent-return
}

/**
 * This function uses the authCode and scope returned from authorizeWithEmail
 * after the user enters the 6 digits OTP code
 * It makes a call to Bolt-FetchOAuthToken controller to fetch Oauth token & refresh token
 * @param {string} authCode - auth Code
 * @param {string} scope - scope, both params are returned from authorizeWithEmail
 * @returns {Object} - an Ajax call to fetch oAuth token
 */
function authenticateUserWithCode(authCode, scope) {
    const authenticateUserUrl = $('.authenticate-bolt-user').val();
    const reqBody = {
        code: authCode,
        scope: scope
    };
    return $.ajax({
        url: authenticateUserUrl,
        method: 'GET',
        data: reqBody,
        error: function (jqXHR, error) {
            console.log(error);
        }
    });
}

/**
 * Get Account Details.
 * This function passes the Oauth token to bolt and retrieve the account details of a shopper
 * @param {string} oAuthToken - oAuth Token
 * @returns {Object} - an ajax call to fetch account details
 */
function getAccountDetails(oAuthToken) {
    const accountDetailUrl = $('.get-bolt-account-details').val();
    const reqBody = {
        bearerToken: oAuthToken
    };
    $.spinner().start();
    return $.ajax({
        url: accountDetailUrl,
        method: 'GET',
        data: reqBody,
        success: function (data) {
            $('body').trigger('bolt:proceedOtpModal');
            // Remove the bolt logout cookie
            document.cookie = $('.bolt-cookie-name').val();
            window.location.href = data.redirectUrl;
        },
        error: function (jqXHR, error) {
            $.spinner().stop();
            console.log(error);
        }
    });
}

/**
 * Check Account And Fetch Detail
 * This function makes a call to bolt backend with the user email,
 * and log the user into their bolt account if the user has one
 * at the end of the login flow we redirect the user to the final page
 * where they can click place order so this function
 * doesn't return anything
 * @returns {void}
 */
export function checkAccountAndFetchDetail() {
    const emailInput = $('#email-guest');
    const customerEmail = emailInput.val();
    const checkBoltAccountUrl = $('.check-bolt-account-exist').val() + '=' + encodeURIComponent(customerEmail);
    const $accountCheckbox = $('#acct-checkbox');
    if ($accountCheckbox) {
        $accountCheckbox.show();
    }
    $.spinner().start();
    $.ajax({
        url: checkBoltAccountUrl,
        method: 'GET',
        success(data) {
            if (!data) return;
            if (data.has_bolt_account) {
                login(customerEmail);
                if ($accountCheckbox) {
                    $('#acct-checkbox').hide();
                }
            } else {
                const boltCheckboxContainer = queryFirst('#acct-checkbox');
                if (boltCheckboxContainer) {
                    $('body').trigger('bolt:createAccountCheckbox');
                }
                $('.submit-customer').removeAttr('disabled'); // enable checkout button for non Bolt shopper
            }
            $.spinner().stop();
            window.BoltAnalytics.checkoutStepComplete(EventAccountRecognitionCheckPerformed, { hasBoltAccount: data.has_bolt_account, detectionMethod: 'email' });
        },
        error: function (jqXHR, error) {
            $.spinner().stop();
            console.log(error);
        }
    });
}

/**
 * making an ajax call to sfcc backend to clear bolt account data
 */
export function logout() {
    const url = $('.data-bolt-platform-side-logout-url').val();
    $.ajax({
        url: url,
        method: 'POST',
        success: function (data) {
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            if (err.responseJSON.message) {
                $('.error-message').show();
                $('.error-message-text').text(err.responseJSON.message);
            }
        }
    });
}

/**
 * LP customisation - Removing detect bolt auto login
 */
// exports.detectAutoLogin = function () {
//     login(null);
// };

/**
 * Get cookie from browser
 * @param {string} cookieName - cookie name
 * @returns {string} cookie value
 */
export function getCookie(cookieName) {
    let name = cookieName + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) { // eslint-disable-line no-plusplus
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
