'use strict';

const $body = $(document);
const invalidClass = 'is-invalid';
const { queryFirst, hasClass, removeClass, addClass } = require('../domUtil');
const { appendToUrl } = require('../util');
const { trackWishlist } = require('../components/etmc');
const { addToWishlist: addToWishlistTemplates } = require('../templates');
const { WISHLIST_NAME_REGEX } = require('../components/shippingPreference/constants')
const isMcEnable = $('#mcEnableAnalytics').val();

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {boolean} isCart - param to identify cart page or not
 * @param {Object} button - button that was clicked to add a product to the wishlist
 */
function displayMessage(data, isCart, button) {
    if (isCart) {
        const { uuid } = button.dataset;
        const cartProductCard = queryFirst('.product-info.uuid-' + uuid);
        const targetElement = queryFirst('.remove-product', cartProductCard);
        const { action, pid, name, isGiftCard, giftCardAmount } = targetElement.dataset;
        const itemToMove = {
            actionUrl: action,
            productID: pid,
            productName: name,
            uuid,
            isGiftCard,
            giftCardAmount
        };
        $('body').trigger('afterRemoveFromCart', itemToMove);
        $('.cart-page .cart-delete-confirmation-btn').trigger('click');
    } else {
        const addToWishlistButton = button;
        $('body').trigger('addedToWishlist', addToWishlistButton);
        addClass(button, 'added-to-wish-list');
        const addedToWishlistText = addToWishlistButton.dataset.selectedText;
        addToWishlistButton.setAttribute('aria-label', addedToWishlistText);
    }
    $.spinner().stop();
}

/**
 * Adds Product to wishlist
 * @param {string} url - service url to add product to wishlist
 * @param {Object} wishlistedProductData - Object with product id, option id, option value, isGiftCard and giftCardAmount Details
 * @param {boolean} isCart - param to identify cart page or not
 * @param {boolean} listId - wishlist ID
 * @param {Object} buttonElement - button that was clicked to add a product to the wishlist
 */
function addProductToWishlist(
    url,
    wishlistedProductData,
    isCart,
    listId,
    buttonElement
) {
    const { pid, isGiftCard, giftCardAmount, optionId, optionVal, colorValue, fitSizeValue } = wishlistedProductData;
    const addToWishlistModal = $('#addToWishlistModal');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            pid: pid,
            optionId: optionId,
            optionVal: optionVal,
            isGiftCard,
            id: listId,
            giftCardAmount,
            fitSizeValue,
            colorValue
        },
        success: function (data) {
            if (data.success && isMcEnable === 'true') {
                // update Marketing Analytics trackWishlist event
                if (data.mcAnalyticsArray) {
                    trackWishlist(data.mcAnalyticsArray);
                }
            }
            if (listId) {
                $(addToWishlistModal).modal('hide');
            }
            displayMessage(data, isCart, buttonElement);
        },
        error: function (err) {
            if (listId) {
                $(addToWishlistModal).modal('hide');
            }
            displayMessage(err, isCart, buttonElement);
        }
    });
}

/**
 * Generates the modal window on the first call.
 * @param {HTMLElement} srcElement - add to wishlist initiator element
 */
function getModalHtmlElement(srcElement) {
    if ($('#addToWishlistModal').length === 0) {
        const addToWishlist = $(addToWishlistTemplates.addToWishlistModal);
        addToWishlist.data('srcElement', srcElement);
        $('body').append(addToWishlist);
    }
    else {
        $('#addToWishlistModal').find('.modal-body').empty();
    }
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {Object} wishlistedProductData - Object with product id, option id, option value, isGiftCard and giftCardAmount details
 * @param {boolean} isCart - param to identify cart page or not
 * @param {jQuery.event} event - Event object which trigger the action
 */
function fillModalElement(selectedValueUrl, wishlistedProductData, isCart, event) {
    $.spinner().start();
    const { pid, optionId, optionVal, isGiftCard, giftCardAmount, selectedColor, selectedFit } = wishlistedProductData;
    const url = appendToUrl(selectedValueUrl, { pid: pid });
    const createNewCollectionPLP = queryFirst('.create-list-collection-plp');
    const hiddenClass = 'd-none';
    if (createNewCollectionPLP) {
        addClass(createNewCollectionPLP, hiddenClass);
    }

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'html',
        success: function (data) {
            $.spinner().stop();
            getModalHtmlElement(event);
            const addToWishlistModal = queryFirst('#addToWishlistModal');
            const addToWishlistBody = queryFirst('.modal-body', addToWishlistModal);
            addToWishlistBody.innerHTML = data;
            $(addToWishlistModal).modal({
                backdrop: true
            });
            $(addToWishlistModal).modal('show');

            const quickViewModal = queryFirst('#quickViewModal');
            if (hasClass(quickViewModal, 'show')) $(quickViewModal).modal('hide');
            const addToWishlistAttributes = queryFirst('.existing-list-collections .add-list-item', addToWishlistModal);
            if (isCart) addToWishlistAttributes.setAttribute('data-uuid', event.dataset.uuid);
            addToWishlistAttributes.setAttribute('data-pid', pid);
            addToWishlistAttributes.setAttribute('data-option-id', optionId);
            addToWishlistAttributes.setAttribute('data-option-value', optionVal);
            addToWishlistAttributes.setAttribute('data-is-gift-card', isGiftCard);
            addToWishlistAttributes.setAttribute('data-gift-card-amount', giftCardAmount);
            addToWishlistAttributes.setAttribute('data-list-id', queryFirst('input[name="listsNameRadio"]:checked').dataset.id);
            addToWishlistAttributes.setAttribute('data-color-value', selectedColor);
            addToWishlistAttributes.setAttribute('data-fit-size-value', selectedFit);
            handleAddToWishlistActions(wishlistedProductData, isCart, event);
        },
        error: function (err) {
            $.spinner().stop();
        }
    });
}

/**
 * handles all the actions to add the product to wishlist.
 * @param {Object} wishlistedProductData - Object with product id, option id, option value, isGiftCard and giftCardAmount details
 * @param {boolean} isCart - param to identify cart page or not
 * @param {jQuery.event} event - Event object which trigger the action
 */
function handleAddToWishlistActions(wishlistedProductData, isCart, event) {
    const existingListCollectionContent = queryFirst('.existing-list-collections');
    const createNewCollectionPLP = queryFirst('.create-list-collection-plp');
    const listNameInput = queryFirst('#list-name');
    const createNewListbutton = queryFirst('.add-new-list-plp');
    const invalidFeedback = queryFirst('.invalid-feedback', createNewCollectionPLP);
    listNameInput.value = '';
    createNewListbutton.disabled = true;
    listNameInput.addEventListener('input', function (e) {
        const listNameTrimText = listNameInput && listNameInput.value && listNameInput.value.trim();
        createNewListbutton.disabled = !listNameTrimText.length;
    });
    const hiddenClass = 'd-none';
    $(addToWishlistModal).off('change.listsNameRadio').on('change.listsNameRadio', '.add-product-to-list input[name="listsNameRadio"]', function (e) {
        e.preventDefault();
        const addToWishlistListAttr = queryFirst('.add-list-item', addToWishlistModal);
        const { id } = this.dataset;
        if (!id) {
            $('.js-create-list-radio').trigger('click');
        }
        addToWishlistListAttr.setAttribute('data-list-id', id);
    });

    $(addToWishlistModal).off('click.addToWishlist').on('click.addToWishlist', '.add-list-item', function (e) {
        e.preventDefault();
        const { target } = e;
        const { actionUrl, listId } = target.dataset;
        $.spinner().start();
        addProductToWishlist(actionUrl, target.dataset, isCart, listId, event);
    });

    $(addToWishlistModal).off('click.createListRadio').on('click.createListRadio', '.js-create-list-radio', function (e) {
        e.preventDefault();
        listNameInput.value = '';
        if (invalidFeedback) {
            removeClass(listNameInput, invalidClass);
        }
        createNewListbutton.disabled = true;
        addClass(existingListCollectionContent, hiddenClass);
        removeClass(createNewCollectionPLP, hiddenClass);
    });

    $(addToWishlistModal).on('click', '.close-create-list-popup', function (e) {
        e.preventDefault();
        removeClass(existingListCollectionContent, hiddenClass);
        addClass(createNewCollectionPLP, hiddenClass);
    });

    $(queryFirst('.create-list-collection-plp')).on('click', '.add-new-list-plp', function (e) {
        e.preventDefault();
        const { href, actionUrl } = this.dataset;
        const { selectedColor, selectedFit } = wishlistedProductData;
        let listName = listNameInput.value && listNameInput.value.trim();

        if (listName && !WISHLIST_NAME_REGEX.test(listName)) {
            if (invalidFeedback) {
                addClass(listNameInput, invalidClass);
                invalidFeedback.textContent = listNameInput.dataset.patternMismatch;
            }
            return;
        }
        removeClass(listNameInput, invalidClass);
        listName = listName.replace(/\s\s+/g, ' ');
        $.spinner().start();
        $.ajax({
            url: href,
            type: 'post',
            dataType: 'json',
            data: {
                wishlistName: listName
            },
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                    const listUUID = data.wishListUUID;
                    wishlistedProductData.fitSizeValue = selectedFit;
                    wishlistedProductData.colorValue = selectedColor;
                    addClass(createNewCollectionPLP, hiddenClass);
                    addClass(existingListCollectionContent, hiddenClass);
                    $(addToWishlistModal).modal('hide');
                    addProductToWishlist(actionUrl, wishlistedProductData, isCart, listUUID, event);
                }
                else {
                    invalidFeedback.textContent = data.msg;
                    addClass(listNameInput, invalidClass);
                }
            },
            error: function (err) {
                $.spinner().stop();
                $(addToWishlistModal).modal('hide');
            }
        });
    });
}


module.exports = {
    addToWishlist: function () {
        $body.on('click', '.move-product-to-wishlist .move', function (e) {
            e.preventDefault();
            $.spinner().start();
            const { target } = e;
            const url = target.getAttribute('href');
            const { pid, isGiftCard, giftCardAmount } = target.dataset;
            if (!url || !pid) {
                return;
            }
            let productMovedToWishlist = { pid, optionId: null, optionVal: null, isGiftCard, giftCardAmount };
            fillModalElement(url, productMovedToWishlist, true, target);
        });

        $body.on('click', '.add-to-wish-list', function (e) {
            e.preventDefault();
            var $buttonElement = $(this);
            const { href } = this.dataset;
            $.spinner().start();
            let wishlistProductContainer = $buttonElement.closest('.product');
            let pid = '';
            let giftCardAmount = 0;
            let isGiftCard = false;

            if (wishlistProductContainer.length) {
                pid = wishlistProductContainer.attr('data-wishlist-id');
                const productTile = queryFirst('.product-tile', wishlistProductContainer[0]);
                isGiftCard = productTile && hasClass(productTile, 'gift-card-tile');
            } else {
                wishlistProductContainer = $buttonElement.closest('.product-detail');
                pid = wishlistProductContainer.attr('data-wishlist-id');
                if (wishlistProductContainer.hasClass('gift-card-main')) {
                    isGiftCard = true;
                    giftCardAmount = document.getElementById('gift_card_amount').value;
                }
            }

            if (!href || !pid) {
                return;
            }

            var optionId = $buttonElement
                .closest('.product-detail')
                .find('.color-attribute .swatch-circle.selected')
                .attr('data-attr-value');
            var optionVal = $buttonElement
                .closest('.product-detail')
                .find('.size-btn.selected')
                .attr('data-value-id');

            const selectedColorContainer = wishlistProductContainer.find('.color-container .color-list.swatches .swatch-value.selected, .product-tile  .color-swatches .product-tile-swatch.selected');
            const selectedFitContainer = wishlistProductContainer.find('.non-color-container .non-color-list.fitsize-swatches .fit-product-type.selected');
            const selectedColor = selectedColorContainer.length ? selectedColorContainer.attr('data-attr-value') : '';
            const selectedFit = selectedFitContainer.length ? selectedFitContainer.attr('data-fitsizeswatch-name') : '';

            optionId = optionId || null;
            optionVal = optionVal || null;

            let addWishlistButtonData = { pid, isGiftCard, giftCardAmount, optionId, optionVal, selectedColor, selectedFit };

            fillModalElement(href, addWishlistButtonData, false, this);
        });
    }
};
