/**
 * Return the iso language code based on local of the site
 * @param {string} locale - current locale
 * @returns {string} - ISO code
 */
export function getISOCodeByLocale(locale) {
    return locale.replace('_', '-').toLowerCase();
}

/**
 * Checks if the email value entered is correct format
 * @param {string} email - email string to check if valid
 * @returns {boolean} Whether email is valid
 */
export function validateEmail(email) {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    return regex.test(email);
}

/**
 * Safely gets nested object properties. Returns the value if found, undefined if not found.
 * @param {*} obj The parent object in which the property exists
 * @param {*} keyString String denoting where in the parent object your target property should exist
 * @param  {...any} replaceValues Values in the keyString to replace -- reference in the keyString with a number encapsulated in {} (e.g. {0}, {1}, etc)
 * @return {Object} returns nested object properties
 */
export function getNestedValue(obj, keyString, ...replaceValues) {
    const keys = keyString.split(/\[|\]|\./).filter(el => el !== '');
    return keys.reduce((o, i) => (o || {})[/\{\d+\}/.test(i) ? replaceValues[i.split(/\{|\}/)[1]] : i], obj);
}